import '../../../app.scss';
import React, { useState } from "react";
import CenOurBusinessCardData from '../../../Assets/FakeData/CenOurBusinessCardData/CenOurBusinessCardData';
import CenOurBusinessCardItem from './CenOurBusinessCardItem';

function CenOurBusinessCard(){

    const [businessCardData] = useState(CenOurBusinessCardData);

    return(
        <>
            <div className="business_allcards container">
                <div className="business_allcards_inner">
                    {
                        businessCardData.map((businessCardData) => 
                            <CenOurBusinessCardItem key={businessCardData.id} businessCardData={businessCardData}/>
                        )
                    }
                </div>
            </div>
        </>
    )
}
export default CenOurBusinessCard;