import '../App.css';
import React from "react";
import CssBodyComponent from "../Components/CssBodyComponent";
import MainFooter from '../Components/MainFooter';
import DedicatedSuccess from '../Pagesection/Corporate/DedicatedSuccess';
import ReviewsCards from '../Pagesection/Corporate/ReviewsCards';
import WorkWithUs from '../Pagesection/Corporate/WorkWithUs';
// import TowardsHealthyPlanet from "../Pagesection/";


function Corporate(){
    window.scrollTo({top: 0, behavior: 'smooth'})
    return(
        <>
            <CssBodyComponent />
        {/* <Header /> */}
            <div className="home-hero-img">
                <div className="hero-main-title-page">
                    <h2>Corporate</h2>
                    <p><span>Home</span>- Corporate</p>
                </div>
                <a href="!#" className="clicktobottom-animate">
                    <div className="indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                        {/*<span></span>*/}
                    </div>
                </a>
            </div>
            <DedicatedSuccess />
            <ReviewsCards />
            <WorkWithUs />  

            <MainFooter />
        </>
    )
}
export default Corporate;