import '../../Styles/_about.scss';
import React from "react";
import AboutWhoWeImg from '../../Assets/Images/About/who-we-are-group-img.webp';
import {useTranslation} from "react-i18next";

export default function AboutMissionVision() {
    // eslint-disable-next-line
    const {t} = useTranslation();
    return (
        <>
            <div className="about-who-we-are-main">
                <div className="about-who-we-are-inner">
                    <div className="about-whowe-are-rightimg">
                        <img src={AboutWhoWeImg} alt=""/>
                    </div>

                    <div className="about-whowe-are-left">
                        <div className="about-who-we-are-title">
                            <h2>Who We Are</h2>
                        </div>
                        <div className="about-who-we-are-texts">
                            <p>With a total of approximately 6,000 Revolution Laundry laundries installed in 12
                                countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal,
                                Portugal, Me Group was the first brand to introduce outdoor self-service laundry kiosks
                                in 2012, and is now the leading brand in Europe.
                                <br></br>
                                <br></br>
                                The group reinforces its dominant position in the market thanks to a rapid growth of
                                deployment in all territories, an extension of its portfolio of form.</p>
                        </div>

                        <div className="about-who-we-are-btn">
                            <button>
                                About More
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            {/*Our Vision*/}
            <div className="about-who-we-are-main">
                <div className="about-who-we-are-inner">
                    <div className="about-whowe-are-left">
                        <div className="about-who-we-are-title">
                            <h2>Who We Are</h2>
                        </div>
                        <div className="about-who-we-are-texts">
                            <p>With a total of approximately 6,000 Revolution Laundry laundries installed in 12
                                countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal,
                                Portugal, Me Group was the first brand to introduce outdoor self-service laundry kiosks
                                in 2012, and is now the leading brand in Europe.
                                <br></br>
                                <br></br>
                                The group reinforces its dominant position in the market thanks to a rapid growth of
                                deployment in all territories, an extension of its portfolio of form.</p>
                        </div>

                        <div className="about-who-we-are-btn">
                            <button>
                                About More
                            </button>
                        </div>
                    </div>
                    <div className="about-whowe-are-rightimg">
                        <img src={AboutWhoWeImg} alt=""/>
                    </div>
                </div>
            </div>
        </>
    )
}