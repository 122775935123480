import '../App.css';
import React from "react";
import CssBodyComponent from "../Components/CssBodyComponent";
import MainFooter from '../Components/MainFooter';
import AboutWoAweAreSection from "../Pagesection/About/AboutWhoWeAre";
import AboutOurCeoSection from "../Pagesection/About/AboutOurCeo";
import AboutOurLeadershipSection from "../Pagesection/About/AboutOurLeaderShip";
import AboutMissionVisionSection from "../Pagesection/About/AboutMissionVision";
import AboutMapData from "../Pagesection/About/AboutVectorMap";

function AboutUs(){
    window.scrollTo({top: 0, behavior: 'smooth'})
    return(
        <>
            <CssBodyComponent/>
        {/* <Header /> */}
            <div className="home-hero-img about-hero-img">
                <div className="hero-main-title-page">
                    <h2>About Us</h2>
                    {/*<p><span>Home</span>- About</p>*/}
                </div>
                <a href="!#" className="clicktobottom-animate">
                    <div className="indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                        {/*<span></span>*/}
                    </div>
                </a>
            </div>

            <AboutOurCeoSection />
            <AboutOurLeadershipSection />
            <AboutWoAweAreSection />
            <AboutMissionVisionSection />
            <AboutMapData />
            <MainFooter />
            
        </>
    )
}
export default AboutUs;