import "../../App.css";
import React from 'react';
import {useTranslation} from "react-i18next";

export default function TowardsHealthyPlanet(){
    const { t } = useTranslation();
    return (
        <>
        <div className="sus-toward-health-section">
            <div className="sus-health-section-inner">
              <div className="sus-health-left">
                <h3>{t('sustainability_commitment_towards_title')}</h3>

              </div>
              <div className="sus-health-right">
                <p className="health-fisttext">
                    {t('sustainability_commitment_towards_text_1')}
                </p>
                <p className="health-second">
                    {t('sustainability_commitment_towards_text_2')}
                </p>
              </div>
            </div>
          </div>
        </>
    )
}