import {useLocation} from "react-router-dom";
import {useEffect} from "react";


// let pageNamePathMatch = pageNamePath.replace("/", "-");


export default function CssBodyComponent(){
    let location = useLocation();
    let pageNamePath = location.pathname.replace("/", "-");
    console.log(pageNamePath);
    useEffect(() => {
        // eslint-disable-next-line no-self-compare
        if (pageNamePath === pageNamePath) {
            document.body.classList.add('page-template' + pageNamePath)
            return () => {
                document.body.classList.remove('page-template' + pageNamePath)
            }
        }
    }, [pageNamePath])
}