import "../../app.scss";
import React from "react";
import {useTranslation} from "react-i18next";
// import LocationCarDetails from "../../Library/LocationData/LocationCardData";

import locationCoverd1 from "../../Assets/Images/Locations/location-coverd1.webp"
import locationSide1 from "../../Assets/Images/Locations/location-side-photo1.webp"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons"

const faFacebookico = <FontAwesomeIcon icon={faFacebook}/>
const faInstagramico = <FontAwesomeIcon icon={faInstagram}/>
const faLinkedinico = <FontAwesomeIcon icon={faLinkedin}/>
const faTwitterico = <FontAwesomeIcon icon={faTwitter}/>
const faYoutubeico = <FontAwesomeIcon icon={faYoutube}/>


export default function LocationDetailSection(locationProps) {
    const {t} = useTranslation();
    return (
        <>
            <div className="location-name-details-texts-main">
                <h3 className="location-name-details-texts-main-name" data-country-name="">South Africa</h3>
                <div className="location-covered-photo-top ajax-loading">
                    <img src={locationCoverd1} alt={locationCoverd1}/>
                </div>
                <div className="location-address-photo">
                    <div className="location-adress-inner-left">
                        <div className="location-adress-inner-lft-heder">
                            <h4> {t('location_location_address')}</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.unknown printer
                                took a galley.</p>
                        </div>
                        <div className="location-street-addr">
                            <p>Quartier Tahagart BP
                                458, 11000
                                <br/>
                                Tamanrasset, Algeria
                                +213 29 34 71 35
                            </p>
                        </div>
                        <div className="location-addr-left-inner-social-icon">
                            <ul className="location-addr-social-icon">
                                <li className="lists-of-location-social-icon lists-of-location-social-fb"><a
                                    href={locationProps.cardFBlink}>{faFacebookico}</a></li>
                                <li className="lists-of-location-social-icon lists-of-location-social-ig"><a
                                    href={locationProps.cardIGlink}>{faInstagramico}</a></li>
                                <li className="lists-of-location-social-icon lists-of-location-social-linkedin"><a
                                    href={locationProps.cardLinkedlink}>{faLinkedinico}</a></li>
                                <li className="lists-of-location-social-icon lists-of-location-social-twitter"><a
                                    href={locationProps.cardTWlink}>{faTwitterico}</a></li>
                                <li className="lists-of-location-social-icon lists-of-location-social-yt"><a
                                    href={locationProps.cardYTlink}>{faYoutubeico}</a></li>
                            </ul>
                        </div>

                        <div className="location-addr-left-explore-btn">
                            <a className="location-addr-left-explore-url" href target='_blank'>Explore</a>
                        </div>
                    </div>


                    <div className="location-inner-right">

                        <a href="https://maps.google.com/maps?q=1st+avenue,New+York" target="_blank"
                           rel="noopener noreferrer">
                            <img src={locationSide1} alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}