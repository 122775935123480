import "../../App.css";
import React from "react";
// import * as jQuery from "jquery";
import ReactPlayer from "react-player"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGooglePlay} from "@fortawesome/free-brands-svg-icons"
import '../../CustomJs/customJs';
import * as jQuery from "jquery";

const hide_div=()=>{
    jQuery('#homepage-ceinemic-video-overlay').hide();
}

const faCirclePlayIco = <FontAwesomeIcon icon={faGooglePlay} />
function HomepageFullVid() {
    // const hide_div = event => {
    //     if (event.detail === 1) {
    //         jQuery('#homepage-ceinemic-video-overlay').hide();
    //     }
    // };
    return (
        <>
            {/*<div className="homepage-cinemic-video-section">*/}
            <div className="homepage-cinemic-video-section-old">
                <div className="cinemic-video-inner">
                    <div className="" id="vim-video-parent">
                        <div className='embed-container' id="vp_2">
                            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                                <ReactPlayer
                                    url={'https://player.vimeo.com/video/154820895?h=a9a924c301'}
                                    config={{
                                        vimeo: {
                                            playerOptions: {
                                                title: 0,
                                                autoplay: 0,
                                            }
                                        }
                                    }}
                                    width='100%'
                                    height='100%'
                                />
                            {/*<iframe id="vp_3" width="auto" height="auto"*/}
                            {/*    src='https://player.vimeo.com/video/154820895?h=a9a924c301&title=0&byline=0&portrait=0"autoplay=1&loop=1&background=1&controls=0&&showinfo=0"'*/}
                            {/*    frameBorder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen title="Iframe"/>*/}

                            <div id="homepage-ceinemic-video-overlay" className="background-image-of-vimeo-video" style={{background:"url(https://www.ucfcu.org/assets/files/1pncTAGd/wide-woman-laptop-kitchen.jpg) no-repeat"}}
                                 onClick={hide_div}
                            >

                                <div className="reactjs-custom-embed-play" role="button">
                                    <i aria-hidden="true" className="eicon-play"/>
                                    <span className="reactjs-home-screen-only">{faCirclePlayIco}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomepageFullVid;