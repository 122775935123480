import '../../../app.scss';
import React from "react";
import galleryPic1 from "../../../Assets/Images/Media/gallery/galleryPic1.webp";
import galleryPic2 from "../../../Assets/Images/Media/gallery/galleryPic2.webp";

function AllImagesOne(){
    return(
        <>
            <div className="All-ImageSection container container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <img className="img-fluid p-3" src={galleryPic1} alt={galleryPic1} />
                    </div>
                    <div className="col-md-4">
                        <img className="img-fluid p-3" src={galleryPic2} alt={galleryPic2} />
                    </div>
                </div>
            
            </div> 
            
        
        </>
    )
}
export default AllImagesOne;