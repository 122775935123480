import "../App.css";
import React from "react";
import CssBodyComponent from "../Components/CssBodyComponent";
import {useTranslation} from "react-i18next";
// import LocationsHeaderTop from '../Pagesection/Locations/LocationsHeaderTop';
import LocationsPortfolio from '../Pagesection/Locations/LocationsPortfolio';
import LocationDetailSection from '../Pagesection/Locations/LocationDetailSection';


import MainFooter from "../Components/MainFooter";


function Locations() {
    const { t } = useTranslation();
    window.scrollTo({top: 0, behavior: 'smooth'})
    return (
        <>
            <CssBodyComponent />
            <div className="home-hero-img location-hero-img">
                <div className="hero-main-title-page">
                    <h2>{t('location_page_title')}</h2>
                    
                </div>
                <a href="!#" className="clicktobottom-animate">
                    <div className="indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                        {/*<span></span>*/}
                    </div>
                </a>
            </div>
            {/* <LocationsHeaderTop /> */}
            <LocationsPortfolio />
            <LocationDetailSection />

            <MainFooter />
        </>
    );
}
export default Locations;
