import '../../Styles/_about.scss';
import React from "react";
// import AboutOurCeoDp from '../../Assets/Images/About/about-our-ceo-image.webp';
import {useTranslation} from "react-i18next";

function AboutOurCeo() {

    const {t} = useTranslation();
    return (
        <>
            <div className="about-our-ceo-main">
                <div className="about-our-ceo-inner">
                    <div className="about-our-ceo-left">
                        <div className="about-our-ceo-img">
                            {/*<img src="" alt={AboutOurCeoDp}/>*/}
                        </div>
                    </div>
                    <div className="about-our-ceo-right-text">
                        <div className="about-our-ceo-right-txt-inner">
                            <div className="about-our-ceo-title">
                                <h2>{t('about_ceo_title')}</h2>
                            </div>
                            <div className="about-our-ceo-texts">
                                <p>
                                    {t('about_ceo_txt')}
                                    <br></br>
                                    <br></br>
                                    {t('about_ceo_txt2')}
                                </p>
                            </div>

                            <div className="about-our-ceo-btn">
                                <button>
                                    {t('about_ceo_btn')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutOurCeo;
