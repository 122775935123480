import "../../app.scss";
import "../../App.css";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const Homefranchisecenturion = () => {
    // const goToTheSustanability =()=>{
    //     return window.location.href ='/Sustainability';
    // }
    const {t} = useTranslation();
    return (
        <>
            <div id="franchcen_main_ads">
                <div className="franchcen_main_ads_child">
                    <div className="franchcen_section-article">
                        <h3 className="franchcen_ads_article_head mb-5">{t('CenturionLaundry')} </h3>
                        <p className="ads_article_text mb-5">
                            {t('CenturionLaundryTxt')}
                            <br></br>
                        </p>
                        <Button variant="light" className="ads_article_btn"
                                onClick={() => window.location.href = '/Sustainability'}>
                            {t('heroBtnLearnMore')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Homefranchisecenturion;