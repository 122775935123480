import "../../app.scss";
import "../../App.css";


const FrancisProjectCountry=(props)=>{
    // console.log(props);
    return(
        <>
            <div className="FrancisProjectCountry_main">
                <div className="FrancisProjectCountry_child">
                   <div className="FrancisProject_single_card">
                        <div className="FrProject_card_inner">
                            <h1>{props.imgtitle}</h1>
                        </div>
                        <div className="FrProject_card_inner">
                        <img className="jhg" src={props.imgimagesrc}  alt="Heroimage"/>
                        </div>
                   </div>

                </div>
            </div>
        </>
    )
}

export default FrancisProjectCountry;