import '../../App.css';
// import React from "react";
import '../../CustomJs/customJs';
import React, {useState} from "react";
// import  {useEffect} from "react";
// import * as jQuery from "jquery";

function Dashboard() {
    window.scrollTo({top: 0, behavior: 'smooth'})

    // useEffect(()=>{
    //
    //     jQuery(window).on('load',function () {
    //
    //         if(jQuery('#nav_icon_show .appdashboard').each(function (i) {
    //             console.log("got it");
    //             jQuery('#nav_site_header').addClass('nav_site_header_dashboard');
    //         })){
    //
    //         }else {
    //             jQuery('#nav_icon_show .appdashboard').each(function (i) {
    //                 console.log("not got it");
    //                 jQuery('#nav_site_header').removeClass('nav_site_header_dashboard');
    //             })
    //         }
    //     })
    //
    // }, [])

    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // User Login info
    const database = [
        {
            username: "user1",
            password: "pass1"
        },
        {
            username: "user2",
            password: "pass2"
        }
    ];

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        var { uname, pass } = document.forms[0];

        // Find user login info
        const userData = database.find((user) => user.username === uname.value);

        // Compare user info
        if (userData) {
            if (userData.password !== pass.value) {
                // Invalid password
                setErrorMessages({ name: "pass", message: errors.pass });
            } else {
                setIsSubmitted(true);
            }
        } else {
            // Username not found
            setErrorMessages({ name: "uname", message: errors.uname });
        }
    };
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );
    const renderForm = (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Username </label>
                    <input type="text" name="uname" required />
                    {renderErrorMessage("uname")}
                </div>
                <div className="input-container">
                    <label>Password </label>
                    <input type="password" name="pass" required />
                    {renderErrorMessage("pass")}
                </div>
                <div className="button-container">
                    <input type="submit" />
                </div>
            </form>
        </div>
    );
    return (
        <div className="appdashboard">
            <div className="login-form">
                <div className="titleoflogin">Sign In</div>
                {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
            </div>
        </div>
    );

}

export default Dashboard;
