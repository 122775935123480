import '../../Styles/_corporate.scss';
import React from "react";

import dedicatedSecPic from "./../../Assets/Images/Corporate/Corporate_pic.webp";
import CorporateClickIcon_One from "./../../Assets/Images/Corporate/Corporate_click_icon.svg";

function DedicatedSuccess(){
    return(
        <>
            <div className="dedicatedSuccessSec container">
                <div className="dedicatedSuccessInner row">
                    <div className="col-md-6">
                        <img className="dedicatedSuccessImg" src={dedicatedSecPic} alt={dedicatedSecPic} />
                    </div>
                    <div className="dedicatedSuccessText col-md-6">
                        <h3 className="dedicatedSuccessTitle">Dedicated to Your Success</h3>
                        <br /><br />
                        <div className="corporateIconSide">
                            <img src={CorporateClickIcon_One} alt={CorporateClickIcon_One} /><p className="dedicatedSuccessDescribe"> The group reinforces its dominant position in the market thanks to a rapid growth of deployment.</p>
                        </div>
                        <div className="corporateIconSide">
                            <img className="corporateIconStyle" src={CorporateClickIcon_One} alt={CorporateClickIcon_One} />
                            <p className="dedicatedSuccessDescribe"> Group was the first brand to introduce outdoor self-service laundry kiosks in 2012, and is now the leading brand in Europe.</p>
                        </div>

                        <div className="corporateIconSide">
                            <img className="corporateIconStyle" src={CorporateClickIcon_One} alt={CorporateClickIcon_One} />
                            <p className="dedicatedSuccessDescribe"> With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Netherlands, Germany, Spain & more.</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default DedicatedSuccess;