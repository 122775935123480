// CentureData
import spainflagcard from "../Assets/Images/spainflagcard.jpg";
import germanyflagcard from "../Assets/Images/germanyflagcard.jpg";
import usaflagcard from "../Assets/Images/usaflagcard.jpg";
import franchflagcard from "../Assets/Images/franchflagcard.jpg";

const CentureData = [
  {
    title: "Germany",
    description: "Dristribution Warehouse",
    cardurl: "https://zehadkhan.com/",
    imagesrc: germanyflagcard,
  },
  {
    title: "France",
    description: "Dristribution Warehouse",
    cardurl: "https://zehadkhan.com/",
    imagesrc: franchflagcard,
  },
  {
    title: "United State",
    description: "Dristribution Warehouse",
    cardurl: "https://zehadkhan.com/",
    imagesrc: usaflagcard,
  },
  {
    title: "Spain",
    description: "Dristribution Warehouse",
    cardurl: "https://zehadkhan.com/",
    imagesrc: spainflagcard,
  },
];


export default CentureData;
