var CenOurBusinessCardData = [
    {
        "id": 1,
        "title": "Commodities",
        "description": "It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking.",
        "image": 'https://iili.io/STNVig.webp',
    },
    {
        "id": 2,
        "title": "Our Laundromates",
        "description": "It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking.",
        "image": 'https://iili.io/STNXfa.webp',
    },
    {
        "id": 3,
        "title": "Consumable",
        "description": "It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking.",
        "image": 'https://iili.io/STN1DP.webp',
    },
    {
        "id": 4,
        "title": "Photo & Impression",
        "description": "It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking.",
        "image": 'https://iili.io/STNGx1.webp',
    },
    {
        "id": 5,
        "title": "Pizza Choco",
        "description": "It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking.",
        "image": 'https://iili.io/STNh0J.webp',
    },
    {
        "id": 6,
        "title": "Small Draft Beer",
        "description": "It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking. It is long established that distracted by the readable content looking.",
        "image": 'https://iili.io/STNjUv.webp',
    },
]

export default CenOurBusinessCardData;