import * as jQuery from 'jquery';
// import Vimeo from "@vimeo/player";
// import * as Players from '@vimeo/player/dist/player.js';
// var gfg = new Players();
let getThewindowSize = jQuery(window).width();
jQuery(function () {
    jQuery("a#menu_items_business").on("mouseover", function (event) {
        // console.log("working");
        jQuery('.drop-down-parent').show();
        jQuery('.menu_single_item_child').show();

    })
    jQuery("a#menu_items_business").on("mouseout", function (event) {
        // console.log("out");
        jQuery('.drop-down-parent').hide();
    })

    // jQuery("a.menu_single_item").on("click", function (event) {
    //     console.log("working");
    //     jQuery('.nav-link-unorder').hide();
    //     // jQuery('.dropdown-items').show();
    // })


    jQuery("a#menu_items_location").on("mouseover", function (event) {
        // console.log("working");
        // jQuery('.dropdown-items').show();
    })
    jQuery("a#menu_items_location").on("mouseout", function (event) {
        // console.log("out");
        // jQuery('.dropdown-items').hide();
    })

    jQuery(window).on("scroll", function () {
        let scrollHeight = jQuery('html').scrollTop();
        // console.log(scrollHeight);

        if (scrollHeight >= 200) {
            // console.log("this is : " + scrollHeight);
            jQuery('#nav_site_header').addClass('scrolled');
        } else {
            jQuery('#nav_site_header').removeClass('scrolled');
        }
    });
    jQuery(window).on("load", function () {
        let scrollHeight = jQuery('html').scrollTop();
        // console.log(scrollHeight);
        if (scrollHeight >= 200) {
            // console.log("this is onload: " + scrollHeight);
            jQuery('#nav_site_header').addClass('scrolled');
        } else {
            // console.log("this is onload: " + scrollHeight);
            jQuery('#nav_site_header').removeClass('scrolled');
        }
        // let getThewindowSize = jQuery(window).width();
        // console.log(getThewindowSize);
        if (getThewindowSize >= 1024) {
            // console.log("this is onload: " + scrollHeight);
            jQuery('#nav_site_header .nav-link-unorder').show();
        } else {
            jQuery('#nav_site_header .nav-link-unorder').hide();
        }

    });
    jQuery(window).on('resize', function () {
        let getThewindowSize = jQuery(window).width();
        // console.log(getThewindowSize);
        if (getThewindowSize >= 1024) {
            // console.log("this is onload: " + scrollHeight);
            jQuery('#nav_site_header .nav-link-unorder').show();
        } else {
            jQuery('#nav_site_header .nav-link-unorder').hide();
        }
    })

    var xdMap = jQuery('path');
    xdMap.on("click", function (event) {
        console.log(jQuery(this).data("id"));
        // jQuery(this).css("cursor","pointer");
        jQuery(this).data("id");
        jQuery('#map-clickable-data-id').children().remove();
        jQuery('#map-clickable-data-id').append(`<h2>${jQuery(this).data("id")}</h2>`);
        jQuery('#map-clickable-data-id').append(`
         <div class="the-clickable-map-country-data">
           <div class="the-data-table">Partner financial institutions</div>
           <div class="the-data-table">Collateral issued (USD)</div>
           <div class="the-data-table">Financing available for SMEs (USD)</div>
           <div class="the-data-table">SME beneficiaries</div>
           <div class="the-data-table">Additional jobs created by SMEs</div>
           <div class="the-data-table">* Figures as of 31 Dec 2020</div>
         </div>
        `);
    })
    // console.clear();
});

// var xd = jQuery('path');
// xd.on("click", function (event) {
//     console.log($(this).data("id"));
// })