import '../../app.scss';
import React from "react";
import {useTranslation} from "react-i18next";

import { useForm } from "react-hook-form";
import { Button } from 'react-bootstrap';

function ContactForm(){
    const { t } = useTranslation();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const contactRegisterInfoSubmit = volunteerData => console.log(volunteerData);
    console.log(watch("example"));

    return(
        <>
            <div className="contactFormArea">
                <h3>{t('contact_us_page_form_title')}</h3>
                <form onSubmit={handleSubmit(contactRegisterInfoSubmit)} className="formInputArea">
                    <h4>{t('contact_us_page_form_name_field')}</h4>
                    <input placeholder={t('contact_us_page_form_placeholder_name')} className="input-design" {...register("name", { required: true })} />
                    {errors.required && <span>{t('contact_us_page_form_required_name')}</span>}
                    <br/>
                    <br/>
                    <h4>{t('contact_us_page_form_email_field')}</h4>
                    <input placeholder={t('contact_us_page_form_placeholder_email')} className="input-design" type="email" {...register("email", { required: true })} />
                    {errors.required && <span>Email is required</span>}
                    <br/>
                    <br/>
                    <h4>{t('contact_us_page_form_country_field')}</h4>
                    <input placeholder={t('contact_us_page_form_placeholder_country')} className="input-design" type="country" {...register("country", { required: true })} />
                    {errors.required && <span>Country is required</span>}
                    <br/>
                    <br/>
                    <h4>{t('contact_us_page_form_phone_field')}</h4>
                    <input placeholder={t('contact_us_page_form_placeholder_phone')} className="input-design" type="tel" {...register("telephone", { required: true })} />
                    {errors.required && <span>Telephone is required</span>}
                    <br/>
                    <br/>
                    <h4>{t('contact_us_page_form_organization_field')}</h4>
                    <input placeholder={t('contact_us_page_form_placeholder_organization')} className="input-design" type="Organization" {...register("Organization", { required: true })} />
                    {errors.required && <span>Organization is required</span>}
                    <br/>
                    <br/>
                    <h4>{t('contact_us_page_form_message_field')}</h4>
                    <textarea placeholder={t('contact_us_page_form_placeholder_message')} className="textarea-design" type="text" rows="8" cols="50" {...register("message", { required: true })}></textarea>
                    {errors.required && <span>Message is required</span>}
                    <br/>
                    <br/>
                    {/* variant="danger" */}
                    <Button className="button-glow" type="submit" name="submit">{t('contact_us_page_form_message_send_btn')}</Button>
                </form>
                <div>
                    {/*{this.state.mailSent &&*/}
                    {/*    <div>Thank you for contcting us.</div>*/}
                    {/*}*/}
                </div>
            </div>
        </>
    )
}
export default ContactForm;