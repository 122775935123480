import "../../App.css";
import React from "react";
import {useTranslation} from "react-i18next";
import SustaCommitImg from "../../Assets/Images/commitment-centure.jpg";

export default function SustainabilityCommitment() {
  const { t } = useTranslation();
  return (
    <>
      <div className="sus-commitment-main">
        <div className="sus-commitment-child">
          <div className="sus-commitment-fist-col">
            <div className="sus-commitment-inner">
              <div className="sus-commitment-left">
                <h3 className="sus-commitment-txt-title">
                  {t('sustainability_our_commitment')}
                </h3>
                <p className="sus-commitment-txt">
                  {t('sustainability_our_comm_text_1')}
                  <br></br>
                  <br></br>
                  {t('sustainability_our_comm_text_2')}
                </p>
              </div>
              <div className="sus-sus-commitment-right">
                <img src={SustaCommitImg} alt={SustaCommitImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
