import "../App.css";
import React from "react";
import FrancisProjectCountry from "../Pagesection/Homepage/FrancisProjectCountry";
import CentureData from "../Library/CentureData";
import {Button} from "react-bootstrap";
import Slider from "react-slick";

export default function HomeCountryCards() {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        easing: 'easeOutElastic',
        // cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className="allcards_country">
                <h3>Our Projects</h3>
                <div className="border-homepage-our-projects"></div>
                <div className="allcards_country_inner">
                    <Slider {...settings}>
                        <div>
                            <FrancisProjectCountry
                                imgtitle={CentureData[0].title}
                                imgimagesrc={CentureData[0].imagesrc}
                                imgcardurl={CentureData[0].cardurl}
                            />
                        </div>
                        <div>
                            <FrancisProjectCountry
                                imgtitle={CentureData[1].title}
                                imgimagesrc={CentureData[1].imagesrc}
                                imgcardurl={CentureData[1].cardurl}
                            />
                        </div>
                        <div>
                            <FrancisProjectCountry
                                imgtitle={CentureData[2].title}
                                imgimagesrc={CentureData[2].imagesrc}
                                imgcardurl={CentureData[2].cardurl}
                            />
                        </div>
                        <div>
                            <FrancisProjectCountry
                                imgtitle={CentureData[3].title}
                                imgimagesrc={CentureData[3].imagesrc}
                                imgcardurl={CentureData[3].cardurl}
                            />
                        </div>

                        <div>
                            <FrancisProjectCountry
                                imgtitle={CentureData[3].title}
                                imgimagesrc={CentureData[3].imagesrc}
                                imgcardurl={CentureData[3].cardurl}
                            />
                        </div>
                    </Slider>


                </div>
                <div className="all-cards-btns">
                    <Button variant="light" className="all-projects-btn" onClick={()=>window.location.href ='/locations'}>
                        View All Projects
                    </Button>
                </div>
            </div>
        </>
    );
}
