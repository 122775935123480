import "../../app.scss";


import React from "react";
import Slider from "react-slick";
import * as jQuery from 'jquery';

import LocationCardData from "../../Library/LocationData/LocationCardData";
import LocationCardSCom from "../Locations/LocationCardSlider";
// import LocationCarDetails from "../Locations/LocationDetailSection";



function LocationsPortfolio() {
    jQuery(function () {
        jQuery(window).on("load", function () {
                jQuery('.slick-slide:odd').addClass("slide-margin-top");
                jQuery('.slick-slide:even').addClass("slide-margin-bottom");
            }
        )
        jQuery(window).on('click',function (){
            jQuery('.slick-slide:odd').addClass("slide-margin-top");
            jQuery('.slick-slide:even').addClass("slide-margin-bottom");
        })
    })

    const LocationsCardsFun = (locationProps) => {
        // console.log(val);
        return (
            <LocationCardSCom
                cardtiD={locationProps.ID}
                cardtitleS={locationProps.cardtitle}
                cardimage={locationProps.cardimage}
                carddescription={locationProps.carddescription}
                cardlink={locationProps.cardlink}
                cardFBlink={locationProps.cardSocialFb}
                cardIGlink={locationProps.cardSociaIg}
                cardTWlink={locationProps.cardSocialTw}
                cardYTlink={locationProps.cardSocialYt}
                cardLinkedlink={locationProps.cardSocialldin}
            />
        )
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,

        autoplay: false,
        autoplaySpeed: 2000,
        cssEase: "linear",

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className="location-main-ReviewsArea">
                <Slider {...settings} className="container mt-5">
                    {LocationCardData.map(LocationsCardsFun)}
                </Slider>
            </div>
        </>
    )
}

export default LocationsPortfolio;