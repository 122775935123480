import "../../app.scss";
import agrifoodplanting from "../../Assets/Images/Agrifood/agrifood-planting.svg";
import agrifoodwaterfall from "../../Assets/Images/Agrifood/agrifood-watering.svg";
import agrifoodassests from "../../Assets/Images/Agrifood/agri-food-garden-assests.svg";
import agrifoodgardengirl from "../../Assets/Images/Agrifood/agrifood-garden-girl.webp";


export default function AgrifoodPortfilioSection() {
    return(
        <>
            <div className="agrifoodportfilio-main">
                <div className="agrifoodportfiliosection-left">

                    <div className="agrifood-portfolio-lists-main">
                        <div className="agrifood-plant-option-img">
                            <img src={agrifoodplanting} alt=""/>
                        </div>
                        <div className="agrifood-plant-option-txt-title">
                            <div className="agrifood-plant-title">
                                <h3>Planting & Growing</h3>
                            </div>
                            <div className="agrifood-plant-txt">
                                <p>The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all.</p>
                            </div>
                        </div>
                    </div>

                    <div className="agrifood-portfolio-lists-main">
                        <div className="agrifood-plant-option-img">
                            <img src={agrifoodassests} alt=""/>
                        </div>
                        <div className="agrifood-plant-option-txt-title">
                            <div className="agrifood-plant-title">
                                <h3>Creative New Garden Assets</h3>
                            </div>
                            <div className="agrifood-plant-txt">
                                <p>The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all.</p>
                            </div>
                        </div>
                    </div>

                    <div className="agrifood-portfolio-lists-main">
                        <div className="agrifood-plant-option-img">
                            <img src={agrifoodwaterfall} alt=""/>
                        </div>
                        <div className="agrifood-plant-option-txt-title">
                            <div className="agrifood-plant-title">
                                <h3>Watering Flowers & Plants</h3>
                            </div>
                            <div className="agrifood-plant-txt">
                                <p>The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="AgrifoodPortfilioSection-right">
                    <img src={agrifoodgardengirl} alt=""/>
                </div>
            </div>
        </>
    )
}