import '../../../app.scss';
import React from "react";
import { Link } from 'react-router-dom';

function CenOurBusinessCardItem(props){
    const businessCardData = props.businessCardData; 
    const {title, description, image} = (businessCardData);

    return(
        <>
            <div className="OurbusinessCard-section">
               <div className="OurbusinessCard-inner">
                  <div className="OurbusinessCard-lists">
                      <img className="img-fluid" src={image} alt={image}/>
                      <div className="OurbusinessCards-on-card">
                          <h3>{title}</h3>
                          <div className="">
                              <p>{description.slice(0, 100)+'......'}</p>
                          </div>
                          <Link to={`/Businesses/${title.replace(/\s/g, '-').toLowerCase()}`} id="link-line">
                              <div className="OurbusinessCard-learnmore">
                                  <h3>Read More</h3>
                              </div>
                          </Link>
                      </div>
                  </div>
               </div>
            </div>
        </>
    )
}
export default CenOurBusinessCardItem;