// BusinessesCardData
import LocatonImg1 from "../../Assets/Images/Locations/Kenya-photoLocation.webp"
import LocatonImg2 from "../../Assets/Images/Locations/Kenya-photoLocation.webp"
import LocatonImgCoverd from "../../Assets/Images/Locations/location-coverd1.webp"


const LocationCardData = [
    {
        ID: 0,
        cardimage: LocatonImg1,
        cardtitle: "Ivory Coast ",
        cardImg: "https://adarbepari.com/wp-content/uploads/2017/08/hardinge-bridge-02.jpg",
        carddescription:
            "With many tourist destinations around the world, Kenya is one of the countries in the African continent with various aesthetically pleasing tourist attractions.",
        cardStretAddr : "journey of a lifetime, 855-272-3274 or  journey of a lifetime, 855-272-3274 or",
        cardlink: "https://pabna.com/",
        cardSocialFb : "https://fb.com/pabna",
        cardSociaIg : "https://instagram.com/pabna",
        cardSocialTw : "https://twitter.com/pabna",
        cardSocialYt : "https://youtube.com/pabna",
        cardSocialldin : "https://likedin.com/pabna",
    },
    {
        ID: 1,
        cardimage: LocatonImg1,
        cardtitle: "Ghana",
        cardImg: "https://www.odysseysafaris.com/wp-content/uploads/2019/05/romantic-lantern-lit-dining-overlooking-the-masai-mara-at-andbeyond-bateleur-camp.jpg",
        carddescription:
            "With many tourist destinations around the world, Kenya is one of the countries in the African continent with various aesthetically pleasing tourist attractions.",
        cardStretAddr : "journey of a lifetime, 855-272-3274 or  journey of a lifetime, 855-272-3274 or",
        cardlink: "https://keniya.com/",
        cardSocialFb : "",
        cardSociaIg : "",
        cardSocialTw : "",
        cardSocialYt : "",
        cardSocialldin : "",
    },
    {
        ID: 2,
        cardimage: LocatonImg2,
        cardtitle: "Benin",
        cardImg: LocatonImgCoverd,
        carddescription:
            "It is long established that distracted by the readable content looking...",
        cardlink: "https://algeria.com/",
        cardSocialFb : "",
        cardSociaIg : "",
        cardSocialTw : "",
        cardSocialYt : "",
        cardSocialldin : "",
    },
    {
        ID: 3,
        cardimage: LocatonImg1,
        cardtitle: "Nigeria",
        cardImg: "https://cdn.britannica.com/97/189797-050-1FC0041B/Night-view-Dhaka-Bangladesh.jpg",
        carddescription:
            "It is long established that distracted by the readable content looking...",
        cardlink: "https://dhaka.com/",
        cardSocialFb : "",
        cardSociaIg : "",
        cardSocialTw : "",
        cardSocialYt : "",
        cardSocialldin : "",
    },
    {
        ID: 4,
        cardimage: LocatonImg1,
        cardtitle: "Kenya",
        cardImg: "https://www.tbsnews.net/sites/default/files/styles/very_big_1/public/images/2019/09/17/rajshahi_road_side_tree.jpg?itok=TJSfFROE",
        carddescription:
            "It is long established that distracted by the readable content looking...",
        cardlink: "https://rajshahi.com/",
        cardSocialFb : "",
        cardSociaIg : "",
        cardSocialTw : "",
        cardSocialYt : "",
        cardSocialldin : "",
    },
    {
        ID: 5,
        cardimage: LocatonImg1,
        cardtitle: "South Africa",
        cardImg: "https://www.tbsnews.net/sites/default/files/styles/very_big_1/public/images/2022/01/28/khulna_md-repon-bhuiyan.jpg?itok=WCssgK6o",
        carddescription:
            "It is long established that distracted by the readable content looking...",
        cardlink: "https://khulna.com/",
        cardSocialFb : "",
        cardSociaIg : "",
        cardSocialTw : "",
        cardSocialYt : "",
        cardSocialldin : "",
    },
    {
        ID: 6,
        cardimage: LocatonImg2,
        cardtitle: "Mozambique",
        cardImg: "https://www.odysseysafaris.com/wp-content/uploads/2019/05/romantic-lantern-lit-dining-overlooking-the-masai-mara-at-andbeyond-bateleur-camp.jpg",
        carddescription:
            "It is long established that distracted by the readable content looking...",
        cardlink: "https://dhittagong.com/",
        cardSocialFb : "",
        cardSociaIg : "",
        cardSocialTw : "",
        cardSocialYt : "",
        cardSocialldin : "",
    },
];

export default LocationCardData;
