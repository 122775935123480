import "../../app.scss";

export default function AgrifoodsHeaderTop() {
    return(
        <>
            <div className="AgrifoodsHeaderTopCard-header">
                <div className="AgrifoodsHeaderTopCard-headermain">
                    <h2>About Our Agrifood</h2>
                </div>
                <div className="AgrifoodsHeaderCard-headertexts">
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scram specimen book.
                    </p>
                </div>
            </div>
        </>
    )
}