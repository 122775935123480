import '../../app.scss';
import React from "react";
import {Link} from 'react-router-dom';

import phoneIcon from "../../Assets/Images/ContactUs/phone_icon.svg";
import emailIcon from "../../Assets/Images/ContactUs/email_icon.svg";
import facebookIcon from "../../Assets/Images/ContactUs/facebok_icon.svg";
import instagramIcon from "../../Assets/Images/ContactUs/intagram_icon.svg";
import linkedinIcon from "../../Assets/Images/ContactUs/Linkedin_icon.svg";
import skypeeIcon from "../../Assets/Images/ContactUs/skypee_icon.svg";
import youtubeIcon from "../../Assets/Images/ContactUs/youtube_icon.svg";
import {useTranslation} from "react-i18next";


function ContactAddress() {
    const { t } = useTranslation();
    return (<>
            <div className="contactAddressArea">
                <div className="generalInquiries">
                    <h5>{t('contact_us_page_GeneralInquiries')}</h5>
                    <div className="phoneContent d-flex">
                        <img src={phoneIcon} alt={phoneIcon}/>
                        <h6><span style={{color: 'black'}}>{t('contact_us_page_teli')}: </span> +88 01 01 01 0 1</h6>
                    </div>
                    <div className="emailContent d-flex">
                        <img src={emailIcon} alt={phoneIcon}/>
                        <h6><span style={{color: 'black'}}>{t('contact_us_page_email')}: </span> <a href='mailto:info@groupcenturions.com'>info@groupcenturions.com</a></h6>
                    </div>
                    <div className="iconContent d-flex">
                        <Link to={"/facebook"}>
                            <img src={facebookIcon} alt={facebookIcon}/>
                        </Link>
                        <Link to={"/facebook"}>
                            <img src={instagramIcon} alt={instagramIcon}/>
                        </Link>
                        <Link to={"/facebook"}>
                            <img src={linkedinIcon} alt={linkedinIcon}/>
                        </Link>
                        <Link to={"/facebook"}>
                            <img src={skypeeIcon} alt={skypeeIcon}/>
                        </Link>
                        <Link to={"/facebook"}>
                            <img src={youtubeIcon} alt={youtubeIcon}/>
                        </Link>

                    </div>
                </div>

                <div className="officeAddress">
                    <h5>{t('contact_us_page_head_office')}</h5>
                    <h6>{t('contact_us_page_address_line1')} <br/> {t('contact_us_page_address_line2')}</h6>
                    
                    <h6>{t('contact_us_page_address_line3')}<br/> {t('contact_us_page_address_line4')}</h6>

                </div>

                <div className="googleMapArea">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.119763973046!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1655818149811!5m2!1sen!2sbd"
                        className="map-area" allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade" title="gMapFrame"/>
                </div>

            </div>

        </>)
}

export default ContactAddress;