import '../App.css';
import React from "react";
import CssBodyComponent from "../Components/CssBodyComponent";
// import ReactDOM from 'react-dom';
// import Header from '../Components/Header';
// import {useTranslation} from "react-i18next";
import ContactTitleSec from '../Pagesection/ContactUs/ContactTitleSec';
import MainFooter from '../Components/MainFooter';
// import axios from 'axios';
// const API_PATH = 'http://localhost/centuration/api/contact/';
//


function ContactPage(){
    // const { t } = useTranslation();
    window.scrollTo({top: 0, behavior: 'smooth'});

    return(
        <>
            <CssBodyComponent />
        {/* <Header /> */}
            <div className="home-hero-img contact-hero-img">
                {/*<div className="hero-main-title-page">*/}
                {/*    <h2>{t('contact_us_page_name')}</h2>*/}
                {/*</div>*/}
                {/*<a href="!#" className="clicktobottom-animate">*/}
                {/*    <div className="indicator">*/}
                {/*        <span></span>*/}
                {/*        <span></span>*/}
                {/*        <span></span>*/}
                {/*        /!*<span></span>*!/*/}
                {/*    </div>*/}
                {/*</a>*/}
            </div>
               
            <ContactTitleSec />
            <MainFooter />
        </>
    )
}
export default ContactPage;