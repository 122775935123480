import '../../Styles/_corporate.scss';
import React from "react";

import one from '../../Assets/Images/Corporate/01.svg';
import two from '../../Assets/Images/Corporate/02.svg';
import three from '../../Assets/Images/Corporate/03.svg';
import four from '../../Assets/Images/Corporate/04.svg';


function WorkWithUs(){
    return(
        <>
            <div className="workWithSection container">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="workWithTitle">Why to Work With Us</h3>
                    </div>
                    <div className="col-md-6">
                        
                        <div className="row">
                            <div className="col-md-6">
                                <div className="WhyWorkingText">
                                    <img src={one} alt={one} />
                                    <h5>Battle Tested Skills</h5>
                                    <p>Create a learning environment and platform for our employees to grow and achieve their fullest potential.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="WhyWorkingText">
                                    <img src={two} alt={two} />
                                    <h5>Full Transparency</h5>
                                    <p>Create sustainable environmental management practices, through a proactive approach.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-6">
                                <div className="WhyWorkingText">
                                    <img src={three} alt={three} />
                                    <h5>Dedicated Teams</h5>
                                    <p>For a diverse group like ours, it’s essential we bolster the financial performance of our businesses.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="WhyWorkingText">
                                    <img src={four} alt={four} />
                                    <h5>We are Cool</h5>
                                    <p>Embody our core values in the way we do business, including respect for cultural diversity.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WorkWithUs;