// eslint-disable-next-line
var CenturionLanguage = {
    "enL": {
        "homepage": {
            "id": 1,
            "home_name": "Name",
            "home_title": `African solutions to African challenges`,
            "paragraph": "As an African diversified conglomerate, our interest in multiple\nindustries indicates our determination to cater to our vision of\nproviding basic needs.",
            "heroBtnLearnMore": "Learn More",
            "heroBtnContactUs": "Contact Us",

            "CenturionLaundry": "Sustainability",
            "CenturionLaundryTxt": "In a rapidly changing post-Covid world, and with the challenges of climate change, we all need to react and adapt.\n\nEmployees, customers, partners, and investors want companies to get involved and demonstrate their value to society.\n We are aware of our responsibilities and the Group is determined to meet these new challenges.",
            "homepage_fra_project_country": "Our Projects",
            "homepage_fra_project_country_btn": "View All Projects",
            "homepage_digital_agency_title": "A Digital Agency That Broadens Your Horizons",
            "homepage_digital_agency_title_txt_1": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum",
            "homepage_digital_agency_title_txt_2": "opposed to using 'Content here, content her making look like readable English. Many desktop publishing packages web page editors now use.",
            "homepage_digital_agency_btn": "Read More",

            "about_main": "About Page",
            "about_ceo_title": "Aliko Dangote, GCON",
            "about_ceo_txt": "With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal, Portugal, Me Group was the first brand to introduce outdoor self-service laundry kiosks in 2012, and is now the leading brand in Europe.",
            "about_ceo_txt2": "The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form.",
            "about_ceo_btn": "About More",
            "about_who_we_are": "Who We Are",
            "about_who_we_are_txt_1": "With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal, Portugal, Me Group was the first brand to introduce outdoor self-service laundry kiosks in 2012, and is now the leading brand in Europe.",
            "about_who_we_are_txt_2": "The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form.",
            "about_who_we_are_abt_more_btn": "About More",
            "about_executive_leader_ship_title": "Meet the Centurion Group Leadership team",
            "about_executive_leader_ship_txt": "Our senior executives bring tremendous experience, visionary thinking and a shared commitment to excellence, creativity, and innovation to the day to day operation of the company.",

            "our_business_page_title": "Our Business",
            "our_business_main": "Our Businesses Page",
            "our_business_main_title": "Our Businesses",
            "our_business_main_title_text": "As an African diversified conglomerate, our interest in multiple industries indicates our determination to cater to our vision of providing basic needs.",

            "sustainability_main": "Sustainability Page",
            "sustainability_main_title": " Our Sustainability Business",
            "sustainability_our_commitment": "Our Sustainability Commitment",
            "sustainability_our_comm_text_1": "With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal, Portugal, Me Group was the first brand to introduce outdoor self-service laundry kiosks in 2012, and is now the leading brand in Europe.\n",
            "sustainability_our_comm_text_2": "The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form.",
            "sustainability_cen_way_title": "The Centurion Way",
            "sustainability_cen_way_title_text_1": "The Pillars of Sustainability are at the core of The Centurion Way. This reflects our personal\n\n",
            "sustainability_cen_way_title_text_2": "commitment to building a socially responsible and impactful business that serves all stakeholders.",
            "sustainability_cen_way_currency_text": "For a diverse group like ours, it’s essential we bolster the financial performance of our businesses.",
            "sustainability_cen_way_build_text": "Build a world-class institution centered around corporate governance best practices.",
            "sustainability_cen_way_cultural_text": "Embody our core values in the way we do business, including respect for cultural diversity.",
            "sustainability_cen_way_environmental_text": "Create sustainable environmental management practices, through a proactive approach.",
            "sustainability_cen_way_unity_text": "Create a learning environment and platform for our employees to grow and achieve their fullest potential.",
            "sustainability_commitment_towards_title": "Our Commitment Towards an Healthy Planet",
            "sustainability_commitment_towards_text_1": "With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal, Portugal, Me Group was the first brand to introduce outdoor.",
            "sustainability_commitment_towards_text_2": "The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form self-service laundry kiosks in 2012, and is now the leading brand in Europe.",

            "location_page_title": "Our presence",
            "location_main_page_title": "Our Business Locations",
            "location_main_page_title_text": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scram specimen book.\n\n",
            "location_location_address": "Location Address",
            "location_location_address_explore_btn": "Explore",

            "media_page_title": "Media",
            "media_page_main_title_news_update": "Our Latest News and Update",
            "media_page_main_title_news_update_text": "Our campaigns get your business in front of the right people at the\nright time to increase traffic and boost engagement",

            "career_page_title": "Career",
            "career_page_tag_line": "Join our world of innovation",
            "career_page_tag_line_impacting": "Impacting Lives",
            "career_page_why_work_title": "Why work with the Centurion Group?",
            "career_page_why_work_title_txt_1": "Meeting the basic needs of people across the world is at the core of what we do at the Centurion Group. We are building a team consisting of the best minds from all over the world doing their best work to bring about this outcome. We have created an environment where staff members are valued, empowered, and can thrive both professionally and personally.",
            "career_page_why_work_title_txt_2": "The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form.",
            "career_page_why_work_abt_more_btn": "About More",
            "career_page_join_us_xp_title": "More than just a career",
            "career_page_join_us_xp_text": "Working with the Centurion Group is more than just a career; it’s meaningful work that improves the lives of millions of people. ",
            "career_page_join_flexible_work": "Flexible Work",
            "career_page_join_yearly_tour": "Yearly Tour",
            "career_page_join_free_holiday": "Free Holiday",
            "career_page_our_value_title_faqs": "We keep our values",
            "career_page_our_value_title_faq_Ques_1": "We win and lose as teams.",
            "career_page_our_value_title_faq_Ans_1": "We are the market leader with a reputation for quality equipment supported by excellent service. All our employees are crucial to the success of the company.",
            "career_page_our_value_title_faq_Ques_2": "We are proud to sign our names on our products.",
            "career_page_our_value_title_faq_Ans_2": "If you have questions about the application or suggestions for If you have questions about the application or suggestions forIf you have questions about the application or suggestions for If you have questions about the application or suggestions for",
            "career_page_our_value_title_faq_Ques_3": "We seek feedback early and often",
            "career_page_our_value_title_faq_Ans_3": "If you have questions about the application or suggestions for If you have questions about the application or suggestions forIf you have questions about the application or suggestions for If you have questions about the application or suggestions for",

            "contact_us_page_name": "Contact",
            "contact_us_page_main_title": "Contact Us",
            "contact_us_page_main_title_txt": "Working with the CENTURION Group is more than just a career. If you have questions or suggestions, Get in touch with us.",
            "contact_us_page_form_title": "Get In Touch With Us",
            "contact_us_page_form_name_field": "Your Name",
            "contact_us_page_form_placeholder_name": "Name",
            "contact_us_page_form_required_name": "Name is required",
            "contact_us_page_form_email_field": "Email",
            "contact_us_page_form_placeholder_email": "Email",
            "contact_us_page_form_country_field": "Country",
            "contact_us_page_form_placeholder_country": "Country",
            "contact_us_page_form_phone_field": "Telephone Number",
            "contact_us_page_form_placeholder_phone": "Telephone Number",
            "contact_us_page_form_organization_field": "Organization",
            "contact_us_page_form_placeholder_organization": "Organization",
            "contact_us_page_form_message_field": "Message",
            "contact_us_page_form_placeholder_message": "Message",
            "contact_us_page_form_message_send_btn": "Send Message",
            "contact_us_page_GeneralInquiries": "General Inquiries",
            "contact_us_page_teli": "Tel",
            "contact_us_page_email": "Email",
            "contact_us_page_head_office": "Head Office",
            "contact_us_page_address_line1": "Union Marble House 1",
            "contact_us_page_address_line2": "Alfred Rewane Road,",
            "contact_us_page_address_line3": "PMB 40032, Falomo Ikoyi, Lagos,",
            "contact_us_page_address_line4": "Nigeria"


        },
        "aboutpage": {}
    },
    "frL": {
        "homepage": {
            "id": 3,
            "home_name": "Nom",
            "home_title": "Des solutions africaines aux défis africains",
            "paragraph": "Nous sommes un conglomérat diversifié et entièrement intégré. Les intérêts du Groupe couvrent un éventail de secteurs en Côte d'Ivoire et à travers l'Afrique. Fournir vos besoins de base.",
            "heroBtnLearnMore": "Apprendre plus",
            "heroBtnContactUs": "Nous contacter",
            "CenturionLaundry": "Durabilité",
            "CenturionLaundryTxt": "Dans un monde post-Covid en pleine mutation, et face aux défis du changement climatique, nous devons tous réagir et nous adapter.\nLes employés, les clients, les partenaires et les investisseurs veulent que les entreprises s'impliquent et démontrent leur valeur à la société. Nous sommes conscients de nos responsabilités et le Groupe est déterminé à relever ces nouveaux défis.",
            "homepage_fra_project_country": "Nos projets",
            "homepage_fra_project_country_btn": "Voir tous les projets",
            "homepage_digital_agency_title": "Fr A Digital Agency That Broadens Your Horizons",
            "homepage_digital_agency_title_txt_1": "Fr It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum",
            "homepage_digital_agency_title_txt_2": "Fr opposed to using 'Content here, content her making look like readable English. Many desktop publishing packages web page editors now use.",
            "homepage_digital_agency_btn": "Fr Read More",

            "about_main": "Fr About Page",
            "about_ceo_title": "Aliko Dangote, GCON",
            "about_ceo_txt": "Fr With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal, Portugal, Me Group was the first brand to introduce outdoor self-service laundry kiosks in 2012, and is now the leading brand in Europe.",
            "about_ceo_txt2": "Fr The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form.",
            "about_ceo_btn": "Fr About More",
            "about_who_we_are": "Fr Who We Are",
            "about_who_we_are_txt_1": "Fr With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal, Portugal, Me Group was the first brand to introduce outdoor self-service laundry kiosks in 2012, and is now the leading brand in Europe.",
            "about_who_we_are_txt_2": "Fr The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form.",
            "about_who_we_are_abt_more_btn": "Fr About More",
            "about_executive_leader_ship_title": "Fr Meet the Centurion Group Leadership team",
            "about_executive_leader_ship_txt": "Fr Our senior executives bring tremendous experience, visionary thinking and a shared commitment to excellence, creativity, and innovation to the day to day operation of the company.",

            "our_business_page_title": "Fr Our Business",
            "our_business_main": "Fr Our Businesses Page",
            "our_business_main_title": "Fr Our Businesses",
            "our_business_main_title_text": "Fr As an African diversified conglomerate, our interest in multiple industries indicates our determination to cater to our vision of providing basic needs.",

            "sustainability_main": "Fr Sustainability Page",
            "sustainability_main_title": "Fr  Our Sustainability Business",
            "sustainability_our_commitment": "Fr Our Sustainability Commitment",
            "sustainability_our_comm_text_1": "Fr With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal, Portugal, Me Group was the first brand to introduce outdoor self-service laundry kiosks in 2012, and is now the leading brand in Europe.\n",
            "sustainability_our_comm_text_2": "Fr The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form.",
            "sustainability_cen_way_title": "Fr The Centurion Way",
            "sustainability_cen_way_title_text": "Fr The Pillars of Sustainability are at the core of The Centurion Way. This reflects our personal\ncommitment to building a socially responsible and impactful business that serves all stakeholders.",
            "sustainability_cen_way_currency_text": "Fr For a diverse group like ours, it’s essential we bolster the financial performance of our businesses.",
            "sustainability_cen_way_build_text": "Fr Build a world-class institution centered around corporate governance best practices.",
            "sustainability_cen_way_cultural_text": "Fr Embody our core values in the way we do business, including respect for cultural diversity.",
            "sustainability_cen_way_environmental_text": "Fr Create sustainable environmental management practices, through a proactive approach.",
            "sustainability_cen_way_unity_text": "Fr Create a learning environment and platform for our employees to grow and achieve their fullest potential.",
            "sustainability_commitment_towards_title": "Fr Our Commitment Towards an Healthy Planet",
            "sustainability_commitment_towards_text_1": "Fr With a total of approximately 6,000 Revolution Laundry laundries installed in 12 countries, mainly in Portugal, Portugal, Portugal, Netherlands, Portugal, Portugal, Portugal, Me Group was the first brand to introduce outdoor.",
            "sustainability_commitment_towards_text_2": "Fr The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form self-service laundry kiosks in 2012, and is now the leading brand in Europe.",

            "location_page_title": "Notre présence",
            "location_main_page_title": "Fr Our Business Locations",
            "location_main_page_title_text": "Fr Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scram specimen book.\n\n",
            "location_location_address": "Fr Location Address",
            "location_location_address_explore_btn": "Fr Explore",

            "media_page_title": "Fr Media",
            "media_page_main_title_news_update": "Fr Our Latest News and Update",
            "media_page_main_title_news_update_text": "Fr Our campaigns get your business in front of the right people at the\nright time to increase traffic and boost engagement",

            "career_page_title": "Fr Career",
            "career_page_tag_line": "Fr Join our world of innovation",
            "career_page_tag_line_impacting": "Fr Impacting Lives",
            "career_page_why_work_title": "Fr Why work with the Centurion?",
            "career_page_why_work_title_txt_1": "Fr Meeting the basic needs of people across the world is at the core of what we do at the Centurion Group. We are building a team consisting of the best minds from all over the world doing their best work to bring about this outcome. We have created an environment where staff members are valued, empowered, and can thrive both professionally and personally.",
            "career_page_why_work_title_txt_2": "Fr The group reinforces its dominant position in the market thanks to a rapid growth of deployment in all territories, an extension of its portfolio of form.",
            "career_page_why_work_abt_more_btn": "Fr About More",
            "career_page_join_us_xp_title": "Fr More than just a career",
            "career_page_join_us_xp_text": "Fr Working with the Centurion Group is more than just a career; it’s meaningful work that improves the lives of millions of people.",
            "career_page_join_flexible_work": "Fr Flexible Work",
            "career_page_join_yearly_tour": "Fr Yearly Tour",
            "career_page_join_free_holiday": "Fr Free Holiday",
            "career_page_our_value_title_faqs": "Fr We keep our values",
            "career_page_our_value_title_faq_Ques_1": "Fr We win and lose as teams.",
            "career_page_our_value_title_faq_Ans_1": "Fr We are the market leader with a reputation for quality equipment supported by excellent service. All our employees are crucial to the success of the company.",
            "career_page_our_value_title_faq_Ques_2": "Fr We are proud to sign our names on our products.",
            "career_page_our_value_title_faq_Ans_2": "Fr If you have questions about the application or suggestions for If you have questions about the application or suggestions forIf you have questions about the application or suggestions for If you have questions about the application or suggestions for",
            "career_page_our_value_title_faq_Ques_3": "Fr We seek feedback early and often",
            "career_page_our_value_title_faq_Ans_3": "Fr If you have questions about the application or suggestions for If you have questions about the application or suggestions forIf you have questions about the application or suggestions for If you have questions about the application or suggestions for",

            "contact_us_page_name": "Fr Contact",
            "contact_us_page_main_title": "Fr Contact Us",
            "contact_us_page_main_title_txt": "Fr Working with the CENTURION Group is more than just a career. If you have questions or suggestions, please contact us.",
            "contact_us_page_form_title": "Fr Get In Touch With Us",
            "contact_us_page_form_name_field": "Fr Your Name",
            "contact_us_page_form_placeholder_name": "Fr Name",
            "contact_us_page_form_required_name": "Fr Name is required",

            "contact_us_page_form_email_field": "Fr Email",
            "contact_us_page_form_placeholder_email": "Fr Email",
            "contact_us_page_form_country_field": "Fr Country",
            "contact_us_page_form_placeholder_country": "Fr Country",
            "contact_us_page_form_phone_field": "Fr Telephone Number",
            "contact_us_page_form_placeholder_phone": "Fr Telephone Number",
            "contact_us_page_form_organization_field": "Fr Organization",
            "contact_us_page_form_placeholder_organization": "Organization",
            "contact_us_page_form_message_field": "Fr Message",
            "contact_us_page_form_placeholder_message": "Fr Message",
            "contact_us_page_form_message_send_btn": "Fr Send Message",
            "contact_us_page_GeneralInquiries": "Fr General Inquiries",
            "contact_us_page_teli": "Fr Tel",
            "contact_us_page_email": "Fr Email",

            "contact_us_page_head_office": "Fr Head Office",
            "contact_us_page_address_line1": "Fr Union Marble House 1",
            "contact_us_page_address_line2": "Fr Alfred Rewane Road,",
            "contact_us_page_address_line3": "Fr PMB 40032, Falomo Ikoyi, Lagos,",
            "contact_us_page_address_line4": "Fr Nigeria"
        },
        "aboutpage": {}
    }
}
export default CenturionLanguage;