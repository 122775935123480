// import '../../Styles/_corporate.scss';
import '../../App.css';
import React from "react";
import Slider from "react-slick";

import qoute from "../../Assets/Images/Corporate/Corporate_qoute.svg"
import person_one from '../../Assets/Images/Corporate/person_1.png';
import person_two from '../../Assets/Images/Corporate/person_2.png';
import person_three from '../../Assets/Images/Corporate/person_3.png';
import person_four from '../../Assets/Images/Corporate/person_4.png';



function ReviewsCards (){

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,

        autoplay: false,
        autoplaySpeed: 2000,
        cssEase: "linear",

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return(
        <>
            <div className="ReviewsArea">
                <div className="ReviewsTagLine">
                    <h3> Look at these Great Reviews! </h3>
                    <p>We are pleased to share with you some great reviews posted by our clients.</p>
                </div>
                
                <Slider {...settings} className="container mt-5">
                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                                We needed a really good, deep cleaning for Maintenance definitely delivered. Our office  is a fairly non traditional layout, as it creative space. We gave pretty specific instructions in terms of what was off-limits and what to focus on.
                            </p>
                            <div className="cardFooter">
                                <img src={person_one} alt={person_one} />
                                <div className="footerText">
                                    <h4>Frank D.</h4>
                                    <p>Germany</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                            Our office takes up the entire floor and is a fairly non traditional layout, as it is a creative space. We needed a really good, deep cleaning for Maintenance definitely delivered. 
                            </p>
                            <div className="cardFooter">
                                <img src={person_two} alt={person_two} />
                                <div className="footerText">
                                    <h4>Diana H.</h4>
                                    <p>France</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                                We gave pretty specific instructions in terms of what was off-limits and what to focus on. Our office takes up the entire floor and is fairly non traditional layout, as it is a creative space.
                            </p>
                            <div className="cardFooter">
                                <img src={person_three} alt={person_three} />
                                <div className="footerText">
                                    <h4>David Matin</h4>
                                    <p>Portugal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                                We needed a really good, deep cleaning for Maintenance definitely delivered. Our office takes up the entire floor and is a fairly non traditional layout.
                            </p>
                            <div className="cardFooter">
                                <img src={person_four} alt={person_four} />
                                <div className="footerText">
                                    <h4>Melissa R.</h4>
                                    <p>Spain</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                                We needed a really good, deep cleaning for Maintenance definitely delivered. Our office takes up the entire floor and is a fairly non traditional layout.
                            </p>
                            <div className="cardFooter">
                                <img src={person_four} alt={person_four} />
                                <div className="footerText">
                                    <h4>Melissa R.</h4>
                                    <p>Spain</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                                We needed a really good, deep cleaning for Maintenance definitely delivered. Our office takes up the entire floor and is a fairly non traditional layout.
                            </p>
                            <div className="cardFooter">
                                <img src={person_four} alt={person_four} />
                                <div className="footerText">
                                    <h4>Melissa R.</h4>
                                    <p>Spain</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                                We needed a really good, deep cleaning for Maintenance definitely delivered. Our office takes up the entire floor and is a fairly non traditional layout.
                            </p>
                            <div className="cardFooter">
                                <img src={person_four} alt={person_four} />
                                <div className="footerText">
                                    <h4>Melissa R.</h4>
                                    <p>Spain</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                                We gave pretty specific instructions in terms of what was off-limits and what to focus on. Our office takes up the entire floor and is fairly non traditional layout, as it is a creative space.
                            </p>
                            <div className="cardFooter">
                                <img src={person_three} alt={person_three} />
                                <div className="footerText">
                                    <h4>David Matin</h4>
                                    <p>Portugal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="reviewAllCards">
                        <div className="reviewCardBody">
                            <img src={qoute} alt={qoute} />
                            <p>
                                We needed a really good, deep cleaning for Maintenance definitely delivered. Our office  is a fairly non traditional layout, as it creative space. We gave pretty specific instructions in terms of what was off-limits and what to focus on.
                            </p>
                            <div className="cardFooter">
                                <img src={person_one} alt={person_one} />
                                <div className="footerText">
                                    <h4>Frank D.</h4>
                                    <p>Germany</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            
            
        </>
    )
}
export default ReviewsCards ;