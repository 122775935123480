import "../../app.scss";
import React from "react";
import * as jQuery from 'jquery';
import '../../CustomJs/customJs';
import LocationCardData from "../../Library/LocationData/LocationCardData";

// import Slider from "react-slick";
export default function LocationCardSlider(locationProps) {
    function locationOdfCard(event) {
        event.preventDefault();
        console.log(event.target.dataset);

        // 👇️ "my-btn"
        console.log(event.target.getAttribute('data-card-id'));
        let idNumOfAttr = event.target.getAttribute('data-card-id');

        let LocCountryName = jQuery('.location-name-details-texts-main-name');
        // console.log(LocCountryName);
        let LocCountryIMG = jQuery('.location-covered-photo-top');
        // console.log(LocCountryIMG);
        let LocCountryAddr = jQuery('.location-adress-inner-lft-heder').find('p');
        // console.log(LocCountryAddr);
        // let LocCountryTxt = jQuery('.location-adress-inner-lft-heder').text();
        let LocCountryStreetTxt = jQuery('.location-street-addr').find('p');
        // console.log(LocCountryStreetTxt);
        // let LocCountrySocial = jQuery('.lists-of-location-social-icon').find('a');

        let LocCountrySocialFb = jQuery('.lists-of-location-social-fb').find('a');
        let LocCountrySocialIg = jQuery('.lists-of-location-social-ig').find('a');
        let LocCountrySocialTwi = jQuery('.lists-of-location-social-twitter').find('a');
        let LocCountrySocialYt = jQuery('.lists-of-location-social-yt').find('a');
        let LocCountrySocialLinked = jQuery('.lists-of-location-social-linkedin').find('a');
        // console.log(LocCountrySocial);
        let LocCountryBtnUrl = jQuery('.location-addr-left-explore-url');
        // console.log(LocCountryBtnUrl);
        let LocCountryMapAddr = jQuery('.location-inner-right').find("img");
        // console.log(LocCountryMapAddr);

        let indexVal = idNumOfAttr;
        console.log("dataOfIndexId " + indexVal);

        if (idNumOfAttr === indexVal) {
            // event.target.setAttribute('src', 'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI');
            LocCountryName.text(LocationCardData[indexVal].cardtitle);
            // LocCountryIMG.attr("src",LocationCardData[indexVal].cardImg);
            // LocCountryIMG.attr("src",LocationCardData[indexVal].cardImg);
            console.log(LocationCardData[indexVal].cardtitle, " cen");
            console.log(LocationCardData[indexVal].cardImg);
            LocCountryIMG.children().remove();
            LocCountryIMG.append(`<img src=${LocationCardData[indexVal].cardImg} alt=${LocationCardData[indexVal].cardImg} />`);
            LocCountryAddr.text(LocationCardData[indexVal].carddescription);
            LocCountryStreetTxt.text(LocationCardData[indexVal].cardStretAddr);
            LocCountryBtnUrl.attr("href", LocationCardData[indexVal].cardlink);
            // LocCountrySocial.attr("href", LocationCardData[indexVal].cardlink);
            LocCountrySocialFb.attr("href", LocationCardData[indexVal].cardSocialFb);
            LocCountrySocialIg.attr("href", LocationCardData[indexVal].cardSociaIg);
            LocCountrySocialTwi.attr("href", LocationCardData[indexVal].cardSocialTw);
            LocCountrySocialYt.attr("href", LocationCardData[indexVal].cardSocialYt);
            LocCountrySocialLinked.attr("href", LocationCardData[indexVal].cardSocialldin);
            LocCountryMapAddr.attr("href", LocationCardData[indexVal].cardImg);
        } else {
            console.log("not correct index")
            return 0;
        }

        // event.target.setAttribute('data-foo', 'bar');
        // console.log(event.target.getAttribute('data-foo')); // 👉️ bar
        //
        // event.target.setAttribute('data-foo', 'baz');
        // console.log(event.target.getAttribute('data-foo')); // 👉️ baz


        // eslint-disable-next-line no-undef

        // let indiumToSetId = jQuery('.location-name-details-texts-main').find('h3').text("Keniya");
        // let indiumToSetImgSrc = jQuery('.location-covered-photo-top').find("img").attr("src","https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI");
        // console.log(indiumToSetId);
        // console.log(indiumToSetImgSrc);


    }

    return (
        <>
            <div className="location-inner-area" onClick={locationOdfCard}>
                <div className="location-card-img-main">
                    <img className="single-imgs-b" data-card-id={locationProps.cardtiD} src={locationProps.cardimage}
                         alt="agrifoodimg"/>
                    <div className="locaton-cardFooter">
                        <div className="locaton-cardFooter-txt" >
                            <div className="LocationName" data-card-id={locationProps.cardtiD}>{locationProps.cardtitleS}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}