import '../../../app.scss';
// import '../../../Styles/_media.scss';
import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import MainFooter from '../../../Components/MainFooter';
import CenOurBusinessCardData from '../../../Assets/FakeData/CenOurBusinessCardData/CenOurBusinessCardData';

document.documentElement.scrollTop = 0;


function CenOurBusinessCardSingle() {

        const {title} = useParams();
        const [foundBusinessCardData] = useState(CenOurBusinessCardData);

        const Data = foundBusinessCardData.filter(ele => ele.title.replace(/\s/g, '-').toLowerCase() === title.replace(/\s/g, '-').toLowerCase());

        return (
            <>
                {/* <Header /> */}
                <div className="home-hero-img">
                    <div className="hero-main-title-page">
                        <h2>Our Businesses</h2>
                        <p><span>Home</span>- Our Businesses</p>
                    </div>
                </div>

                <div className="detailsNews">
                    {
                        Data.map(data => {
                            return (
                                <div className="newsArea-singlePage">
                                    <div className="newsImage">
                                        <img src={data.image} alt={data.image}/>
                                    </div>
                                    {/* <div className="newsPosted">
                                        <h6>Posted by: {data.posted_by}</h6>
                                        <h6>Posted on: {data.date}</h6>
                                    </div> */}
                                    <div className="newsTitle">
                                        <h5>{data.title}</h5>
                                    </div>
                                    <div className="newsDescription">
                                        <p>{data.description}</p>
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

                <MainFooter />


            </>
        )
    }
    export default CenOurBusinessCardSingle;