import '../../app.scss';
import React from "react";
import {useTranslation} from "react-i18next";
import ContactForm from './ContactForm';
import ContactAddress from './ContactAddress';

function ContactTitleSec(){
    const { t } = useTranslation();
    return(
        <>
            <div className="contactTitleSec container">
                <h3>{t('contact_us_page_main_title')}</h3>
                <p>{t('contact_us_page_main_title_txt')} <br /></p>
            </div>
            <div className="contactFormSec container container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <ContactForm />
                    </div>
                    <div className="col-md-4">
                        <ContactAddress />
                    </div>
                </div>
            </div>
            
        
        </>
    )
}
export default ContactTitleSec;