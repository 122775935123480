import "../../app.scss";
import "../../App.css";
import React from "react";
import Card from "react-bootstrap/Card";

import card_img1agri from "../../Assets/Images/agrifoodCardCopy.jpg";
import card_img2 from "../../Assets/Images/mother-with-daughter-laundry.jpg";
import card_img3 from "../../Assets/Images/image-card-1.png";
import card_img4 from "../../Assets/Images/firework-candle.jpg";
import card_img5 from "../../Assets/Images/friendsfood.jpg";
import card_img6 from "../../Assets/Images/elevatecheer.jpg";

import card_imgico_1 from "../../Assets/Images/Homepage/agrifood_ico.svg";
import card_imgico_2 from "../../Assets/Images/Homepage/Icon/Laundromates.svg";
import card_imgico_3 from "../../Assets/Images/Homepage/Icon/Consumable.svg";
import card_imgico_4 from "../../Assets/Images/Homepage/Icon/Photo Impression.svg";
import card_imgico_5 from "../../Assets/Images/Homepage/Icon/Pizza Choco.svg";
import card_imgico_6 from "../../Assets/Images/Homepage/Icon/Draft Beer.svg";



// import {Button} from 'react-bootstrap';
// import ReactDOM from "react-dom";

function MainHomeItemsCard() {
  return (
    <div id="CenturationGallery" className="itemcards_list container container-width-fixed">
      <div className="homepage-business-card-header">
        <h2>Our Businesses</h2>
        <div className="border-homepage-business"></div>

        {/*<p>As an African diversified conglomerate, our interest in multiple <br /> industries indicates our determination to cater to our vision of <br /> providing basic needs.</p>*/}
      </div>
      <div className="items-card-row  row">
        <div className="itemcards_single col-md-4 mb-5">
          <Card className="card-inner" style={{ width: "18rem" }}>
            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}

            <Card.Img
              className="card-image-portfolio"
              variant="top"
              src={card_img1agri}
            />

            <div className="card-over-content">
              <div className="card-over-inner">
                <Card.Text>
                  <Card.Img
                      className="card-image-portfolio-icon-hov"
                      variant="top"
                      src={card_imgico_1}
                  />
                  <h4>Agrifood</h4>
                  
                  It is a long established that a reader distracted by the
                  readable content looking at its layout.
                  <br></br>
                 
                </Card.Text>
                <a href="/Agrifood" className="btn-primary-oncard">Read More</a>
              </div>
            </div>
            <Card.Body className="card-body-section">
              <a href="{facebook}" className="">
                <Card.Title>Agrifood</Card.Title>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </a>
            </Card.Body>
          </Card>
        </div>

        <div className="itemcards_single col-md-4 mb-5">
          <Card className="card-inner" style={{ width: "18rem" }}>
            <Card.Img
              className="card-image-portfolio"
              variant="top"
              src={card_img2}
            />
            <div className="card-over-content">
              <div className="card-over-inner">
                <Card.Text>
                  <Card.Img
                      className="card-image-portfolio-icon-hov"
                      variant="top"
                      src={card_imgico_2}
                  />
                  <h4>Our Laundromates</h4>

                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <a href="#b" className="btn-primary-oncard">Read More</a>
              </div>
            </div>
            <Card.Body className="card-body-section">
              <a href="{facebook}" className="">
                <Card.Title>Our Laundromates</Card.Title>
              </a>
            </Card.Body>
          </Card>
        </div>

        <div className="itemcards_single col-md-4 mb-5">
          <Card className="card-inner" style={{ width: "18rem" }}>
            <a className="card-image-link" href="#c">
              <Card.Img
                className="card-image-portfolio"
                variant="top"
                src={card_img3}
              />
            </a>
            <div className="card-over-content">
              <div className="card-over-inner">
                <Card.Text>
                  <Card.Img
                      className="card-image-portfolio-icon-hov"
                      variant="top"
                      src={card_imgico_3}
                  />
                  <h4>Consumable</h4>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <a href="#b" className="btn-primary-oncard">Read More</a>
              </div>
            </div>
            <Card.Body className="card-body-section">
              <a href="{facebook}" className="">
                <Card.Title>Consumable</Card.Title>
              </a>
            </Card.Body>
          </Card>
        </div>

        <div className="itemcards_single col-md-4 mb-5">
          <Card className="card-inner" style={{ width: "18rem" }}>
            <Card.Img
              className="card-image-portfolio"
              variant="top"
              src={card_img4}
            />
            <div className="card-over-content">
              <div className="card-over-inner">
                <Card.Text>
                  <Card.Img
                      className="card-image-portfolio-icon-hov"
                      variant="top"
                      src={card_imgico_4}
                  />
                  <h4>Photo & Impression</h4>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <a href="#b" className="btn-primary-oncard">Read More</a>
              </div>
            </div>
            <Card.Body className="card-body-section">
              <a href="{facebook}" className="">
                <Card.Title>Photo & Impression</Card.Title>
              </a>
            </Card.Body>
          </Card>
        </div>
        <div className="itemcards_single col-md-4 mb-5">
          <Card className="card-inner" style={{ width: "18rem" }}>
            <Card.Img
              className="card-image-portfolio"
              variant="top"
              src={card_img5}
            />
            <div className="card-over-content">
              <div className="card-over-inner">
                <Card.Text>
                  <Card.Img
                      className="card-image-portfolio-icon-hov"
                      variant="top"
                      src={card_imgico_5}
                  />
                  <h4>Pizza & Choco</h4>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <a href="#b" className="btn-primary-oncard">Read More</a>
              </div>
            </div>
            <Card.Body className="card-body-section">
              <a href="{facebook}" className="">
                <Card.Title>Pizza Choco</Card.Title>
              </a>
            </Card.Body>
          </Card>
        </div>

        <div className="itemcards_single col-md-4 mb-5">
          <Card className="card-inner" style={{ width: "18rem" }}>
            <Card.Img
              className="card-image-portfolio"
              variant="top"
              src={card_img6}
            />
            <Card.Body className="card-body-section">
              <a href="{facebook}" className="">
                <Card.Title>Small Draft Beer</Card.Title>
              </a>
            </Card.Body>
            <div className="card-over-content">
              <div className="card-over-inner">
                <Card.Text>
                  <Card.Img
                      className="card-image-portfolio-icon-hov"
                      variant="top"
                      src={card_imgico_6}
                  />
                  <h4>Small Draft Beer</h4>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <a href="#b" className="btn-primary-oncard">Read More</a>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
export default MainHomeItemsCard;
