import '../../app.scss';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import '../../Styles/_media.scss';
import NewsData from '../../Assets/FakeData/Media/LatestNews';
import LatestNewsData from './LatestNewsData';

function LatestNews() {
    const {t} = useTranslation();
    const [news] = useState(NewsData);

    return (
        <>
            <div className="latestNewsTagSection container">
                <h3> {t('media_page_main_title_news_update')}</h3>
                <div className="border-media-our-news"></div>
                
            </div>
            <div className="container content-fluid">
                <div className="row">
                    {
                        news.map((news) =>
                            <LatestNewsData key={news.id} news={news}/>
                        )
                    }
                </div>
            </div>


        </>
    )
}

export default LatestNews;