import "../../App.css";
import React from 'react';
import {useTranslation} from "react-i18next";

import StaFinance from "../../Assets/Images/susta-finance.png";
import sustaCulture from "../../Assets/Images/susta-culture.png";
import sustaEnvironment from "../../Assets/Images/susta-environment.png";
import sustaGrows from "../../Assets/Images/susta-grows.png";
import institutionSusta from "../../Assets/Images/institution-susta.png";

export default function TheCenturionWay() {
    const { t } = useTranslation();
    return (
        <>
            <div className="sus-centurionway-section">
                <div className="sus-centurionway-inner">
                    <div className="sus-centurionway-header">
                        <h3>{t('sustainability_cen_way_title')}</h3>
                        <p>
                            {t('sustainability_cen_way_title_text_1')}
                            <br></br>
                            {t('sustainability_cen_way_title_text_2')}
                        </p>
                    </div>

                    <div className="sus-portfolio-info-cen-way">
                        <div className="sus-portfolio-info-data">
                            <div className="centure-card-img-single">
                                <img src={StaFinance} alt=""/>
                            </div>
                            <div className="sus-cen-card-img-description">
                                <p> {t('sustainability_cen_way_currency_text')}</p>
                            </div>
                        </div>

                        <div className="sus-portfolio-info-data">
                            <div className="centure-card-img-single">
                                <img src={institutionSusta} alt=""/>
                            </div>
                            <div className="sus-cen-card-img-description">
                                <p>{t('sustainability_cen_way_build_text')}</p>
                            </div>
                        </div>

                        <div className="sus-portfolio-info-data">
                            <div className="centure-card-img-single">
                                <img src={sustaCulture} alt=""/>
                            </div>
                            <div className="sus-cen-card-img-description">
                                <p>{t('sustainability_cen_way_cultural_text')}</p>
                            </div>
                        </div>

                        <div className="sus-portfolio-info-data">
                            <div className="centure-card-img-single">
                                <img src={sustaEnvironment} alt=""/>
                            </div>
                            <div className="sus-cen-card-img-description">
                                <p>{t('sustainability_cen_way_environmental_text')}</p>
                            </div>
                        </div>

                        <div className="sus-portfolio-info-data">
                            <div className="centure-card-img-single">
                                <img src={sustaGrows} alt=""/>
                            </div>
                            <div className="sus-cen-card-img-description">
                                <p>{t('sustainability_cen_way_unity_text')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
