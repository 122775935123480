import '../../Styles/_about.scss';
import React from "react";
import AboutImageOfExe1 from '../../Assets/Images/About/about-our-executive-img1.jpg';
import {useTranslation} from "react-i18next";

export default function AboutOurLeaderShip() {
    // eslint-disable-next-line
    const {t} = useTranslation();
    return (
        <>
            <div className="about-our-leadership-main">
                <div className="our-leadership-title">
                    <h2> {t('about_executive_leader_ship_title')}</h2>
                </div>
                {/* <div className="our-leadership-sub-txt">
                    <p>
                        {t('about_executive_leader_ship_txt')}
                    </p>
                </div> */}

                <div className="about-executive-leadership-lists">

                    <div className="about-executive-single-card">
                        <div className="about-executive-card-img">
                            <img className="image-of-about-executive" src={AboutImageOfExe1} alt=""/>
                            <div className="overlay-about-executive-txt">
                                <div className="executive-name-over-title"> Aboubacar Sanou</div>
                                <div className="executive-name-of-role">President & Chief Executive</div>
                                <br></br>
                                <p className="executive-bio-card-over">Ben joined I&P in December 2020 as Director of
                                    Operations of the program I&P Education to Employment.
                                </p>
                            </div>
                        </div>
                        <div className="about-executive-img-title">
                            <div className="about-executive-name-card">Sani Dangote</div>
                            <div className="about-executive-sub-txt">Group Vice President</div>
                        </div>
                    </div>

                    <div className="about-executive-single-card">
                        <div className="about-executive-card-img">
                            <img className="image-of-about-executive" src={AboutImageOfExe1} alt=""/>
                            <div className="overlay-about-executive-txt">
                                <div className="executive-name-over-title"> Aboubacar Sanou</div>
                                <div className="executive-name-of-role">President & Chief Executive</div>
                                <br></br>
                                <p className="executive-bio-card-over">Ben joined I&P in December 2020 as Director of
                                    Operations of the program I&P Education to Employment.
                                </p>
                            </div>
                        </div>
                        <div className="about-executive-img-title">
                            <div className="about-executive-name-card">Sani Dangote</div>
                            <div className="about-executive-sub-txt">Group Vice President</div>
                        </div>
                    </div>


                    <div className="about-executive-single-card">
                        <div className="about-executive-card-img">
                            <img className="image-of-about-executive" src={AboutImageOfExe1} alt=""/>
                            <div className="overlay-about-executive-txt">
                                <div className="executive-name-over-title"> Aboubacar Sanou</div>
                                <div className="executive-name-of-role">President & Chief Executive</div>
                                <br></br>
                                <p className="executive-bio-card-over">Ben joined I&P in December 2020 as Director of
                                    Operations of the program I&P Education to Employment.
                                </p>
                            </div>
                        </div>
                        <div className="about-executive-img-title">
                            <div className="about-executive-name-card">Sani Dangote</div>
                            <div className="about-executive-sub-txt">Group Vice President</div>
                        </div>
                    </div>


                    <div className="about-executive-single-card">
                        <div className="about-executive-card-img">
                            <img className="image-of-about-executive" src={AboutImageOfExe1} alt=""/>
                            <div className="overlay-about-executive-txt">
                                <div className="executive-name-over-title"> Aboubacar Sanou</div>
                                <div className="executive-name-of-role">President & Chief Executive</div>
                                <br></br>
                                <p className="executive-bio-card-over">Ben joined I&P in December 2020 as Director of
                                    Operations of the program I&P Education to Employment.
                                </p>
                            </div>
                        </div>
                        <div className="about-executive-img-title">
                            <div className="about-executive-name-card">Sani Dangote</div>
                            <div className="about-executive-sub-txt">Group Vice President</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
