import '../app.scss';
import '../App.css';
import '../CustomJs/customJs';
import React from 'react';
import mlogo from '../Assets/Images/mlogo.png';
import jumpToTop from "../Assets/Images/jump-to-top-ico.svg";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
const faFacebookico = <FontAwesomeIcon icon={faFacebook}/>
const faInstagramico = <FontAwesomeIcon icon={faInstagram}/>
const faLinkedinico = <FontAwesomeIcon icon={faLinkedin}/>
const faTwitterico = <FontAwesomeIcon icon={faTwitter}/>
const faYoutubeico = <FontAwesomeIcon icon={faYoutube}/>

// import abstracts from '../Assets/Images/footer-subscribe-Vector.png';
// import {Button} from 'react-bootstrap';
// import ReactDOM from "react-dom";

function MainFooter(){
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //     console.log(window.scrollTo(0, 0))
    // }, [])
    return (
        <div id="cen-mainfooter" className="centuration-main-footer">
            <div className="footer-blank-top-spaces">
                <div className="footer-top-subscribe-mail">
                    <div className="subscribe-mail-header">
                        <h2>SUBSCRIBE TO OUR Centurion</h2>
                        <p>For receiving our news and updates in your inbox directly</p>
                    </div>
                    <div className="footer-subsscibe-mail-box">
                        <input type="mail" placeholder="Your email address"className="" />
                        <button>Subsscibe Now</button>
                    </div>
                </div>
            </div>
        <div className="cen-footer-content">
            <div className="cen-footer-top">
                
            </div>
            <div className="cen-footer-bottom">
                <div className="cen-footer-items">
                    <div className="cen-footer-item-logo cen-footer-bottom-single">
                        <div className="site_logo_image">
                            <img src={mlogo}  alt="Logo"/>
                        </div>
                        <div className="site_logo_text">
                            <p>
                            </p>
                            <ul className="footer-social-icons">
                                <li className="lists-of-footer-social-icon lists-of-location-social-fb"><a
                                    href="!#" rel="external nofollow noopener noreferrer" target='_blank'>{faFacebookico}</a></li>
                                <li className="lists-of-footer-social-icon lists-of-location-social-ig"><a
                                    href="!#" rel="external nofollow noopener noreferrer" target='_blank'>{faInstagramico}</a></li>
                                <li className="lists-of-footer-social-icon lists-of-location-social-linkedin"><a
                                    href="!#" rel="external nofollow noopener noreferrer" target='_blank'>{faLinkedinico}</a></li>
                                <li className="lists-of-footer-social-icon lists-of-location-social-twitter"><a
                                    href="!#" rel="external nofollow noopener noreferrer" target='_blank'>{faTwitterico}</a></li>
                                <li className="lists-of-footer-social-icon lists-of-location-social-yt"><a
                                    href="!#" rel="external nofollow noopener noreferrer" target='_blank'>{faYoutubeico}</a></li>
                            </ul>
                        </div>
                        <div className="footer-app-download-icon">
                            <img src="https://tz-mag-media.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2018/05/10182849/google-play-download-android-app-logo-png-transparent-300x98.png" alt=""/>
                        </div>

                    </div>
                    <div className="cen-footer-item-utily cen-footer-bottom-single">
                        <div className="cen-utily-title cen-footer-menu-title">
                            <h4>Menu</h4>
                        </div>
                        <div className="cen-utily-inner cen-footer-single-inn">
                            <a href="/About">About Us</a>
                            <a href="/Locations">Location</a>
                            <a href="/Media">Media</a>
                            <a href="/Career">Career</a>
                            <a href="/Businesses">Our Businesses </a>
                            <a href="/Sustainability">Sustainability</a>
                            {/*<a href="/Contact">Contact</a>*/}
                        </div>
                    </div>
                    <div className="cen-footer-item-camping cen-footer-bottom-single">
                        <div className="cen-item-camping cen-footer-menu-title">
                            <h4>Privacy</h4>
                        </div>
                        <div className="cen-camping-inner cen-footer-single-inn">
                            <a href="/faqs">FAQ</a>
                            <a href="/Contact">Contact Us</a>
                            <a href="/terms-and-conditions">Terms & conditions</a>
                            <a href="/privacy-policy">Privacy And Policy</a>
                        </div>
                    </div>
                    <div className="cen-footer-item-contactor cen-footer-bottom-single">
                        <div className="cen-item-contactor cen-footer-menu-title">
                            <h4>Contact Address</h4>
                        </div>
                        <div className="cen-contactor-inner cen-footer-single-inn">
                            <a href="#propos">contact@groupcenturions.com</a>
                            <a href="#propos">2563 High street,</a>
                            <a href="#propos">Los Angles, California</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="backtotopid" className="jump-to-top-icon" onClick={goToTop}>
                <img className="jumpToTop" src={jumpToTop} alt=""/>
                <br />
                Back to Top
            </div>
        </div>
        <div className="cen-footer-copyright">
            <div className="cen-footer-copyright-content">
                <p>Copyright © 2022 – Centurion</p>
            </div>
        </div>
    </div>
    )

}

export default MainFooter;

