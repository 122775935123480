import "../App.css";
import React from "react";
import CssBodyComponent from "../Components/CssBodyComponent";

import MainFooter from "../Components/MainFooter";
import SustainabilityCommit from "../Pagesection/Sustainability/SustainabilityCommitment";
import TheCenturionWay from "../Pagesection/Sustainability/TheCenturionWay";
import TowardsHealthyPlanet from "../Pagesection/Sustainability/TowardsHealthyPlanet";

function Sustainability() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  return (
    <>
        <CssBodyComponent />
     <div className="home-hero-img sustainability-hero-img">
         <div className="hero-main-title-page">
             <h2>Sustainability</h2>
             {/*<p><span>Home</span>- Sustainability</p>*/}
         </div>
         <a href="!#" className="clicktobottom-animate">
             <div className="indicator">
                 <span></span>
                 <span></span>
                 <span></span>
                 {/*<span></span>*/}
             </div>
         </a>
     </div>
      <SustainabilityCommit />
      <TheCenturionWay />
      <TowardsHealthyPlanet />
      <MainFooter />
    </>
  );
}
export default Sustainability;
