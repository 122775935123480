import '../../../Styles/_media.scss';
import React from "react";
// import { useNavigate } from "react-router-dom";
import {useParams} from 'react-router-dom';
import {useState} from 'react';
import LatestNews from '../../../Assets/FakeData/Media/LatestNews';
import MainFooter from '../../../Components/MainFooter';

// eslint-disable-next-line react-hooks/rules-of-hooks

document.documentElement.scrollTop = 0;


function NewsPageSingle() {
    // const navigate = useNavigate();

    // const refreshPage = () => {
    //     navigate(0);
    // }
        const {title} = useParams();
        const [foundData] = useState(LatestNews);

        const Data = foundData.filter(ele => ele.title.replace(/\s/g, '-').toLowerCase() === title.replace(/\s/g, '-').toLowerCase());
        // console.log("News Data :", Data);


        // console.log("Found News Data :", foundData);

        // console.log(Data.posted_by, Data.title, Data.description, Data.image, Data.date);
        return (
            <>
                {/* <Header /> */}
                <div className="home-hero-img">
                    <div className="hero-main-title-page">
                        <h2>Media</h2>
                        <p><span>Media</span>- News</p>
                    </div>
                </div>

                <div className="detailsNews">
                    {
                        Data.map(data => {
                            return (
                                <div className="newsArea-singlePage">
                                    <div className="newsImage">
                                        <img src={data.image} alt={data.image}/>
                                    </div>
                                    <div className="newsPosted">
                                        <h6>Posted by: {data.posted_by}</h6>
                                        <h6>Posted on: {data.date}</h6>
                                    </div>
                                    <div className="newsTitle">
                                        <h5>{data.title}</h5>
                                    </div>
                                    <div className="newsDescription">
                                        <p>{data.description}</p>
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

                <MainFooter/>


            </>
        )
    }
    export default NewsPageSingle;