import "../../App.css";
import React from "react";
import digitalAgencyImage from '../../Assets/Images/digital-agency-image.jpg';
import { Button } from "react-bootstrap";

function HomeDigitalAgency() {
  return (
    <>
      <div className="HomeDigitalAgency-section container">
        <div className="home-digitalagency-innovate-header">
          <h3>Join Our World of Innovation</h3>
          <div className="border-homepage-our-innovation"></div>
        </div>
        <div className="HomeDigitalAgency-inner row">
          <div className="HomeDigitalAgency-left-image col-md-6">
            <img className="digital-agency-image" src={digitalAgencyImage}  alt="Heroimage"/>
          </div>
          <div className="HomeDigitalAgency-right-text  col-md-6">
            <div className="HomeDigitalAgency-text-header">
              <h3>A Digital Agency That Broadens Your Horizons</h3>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </p>
              <p>
                opposed to using 'Content here, content her making look like
                readable English. Many desktop publishing packages web page
                editors now use.
              </p>
            </div>
            <div className="home-agency-btn">
                <Button variant="light" className="agency-btn-main" onClick={()=>window.location.href ='/career'}>
                  Read More
                </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeDigitalAgency;
