import "../App.css";
import React from "react";
import {useTranslation} from "react-i18next";
import OurbusinessHeaderTop from '../Pagesection/Ourbusiness/OurbusinessHeaderTop';
// import BusinessPortalCard from "../Components/BusinessPortalCards";
import CssBodyComponent from "../Components/CssBodyComponent";
import MainFooter from "../Components/MainFooter";
import CenOurBusinessCard from "../Pagesection/Ourbusiness/CenOurBusiness/CenOurBusinessCard";

function Ourbusiness() {
    const { t } = useTranslation();
    window.scrollTo({top: 0, behavior: 'smooth'});
  return (
    <>
        <CssBodyComponent />
        <div className="home-hero-img our-business-hero-img">
            <div className="hero-main-title-page">
              <h2>{t('our_business_page_title')}</h2>
            </div>
            <a href="!#" className="clicktobottom-animate">
                <div className="indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                    {/*<span></span>*/}
                </div>
            </a>
        </div>
      <OurbusinessHeaderTop />
      {/* <BusinessPortalCard /> */}

      <CenOurBusinessCard />
      <MainFooter />
    </>
  );
}
export default Ourbusiness;
