import { Link } from "react-router-dom";
import React from "react";
import '../../app.scss';
import '../../App.css';
import '../../Styles/_media.scss';

function LatestNewsData(props){
    const newsData = props.news; 
    const {posted_by, title, description, image, date} = (newsData);

    return(
        <>
           <div className="col-md-4">
                <div className="news-item">
                    <div className="news-image d-flex align-items-end flex-column">
                        <img className="rounded img-fluid" src={image} alt={image}/>
                        <div className="news-date">
                            <p>{date}</p>
                        </div>
                    </div>
                    <div className="posted-person">
                        <h6>{posted_by}</h6>
                    </div>
                    <Link to={`/news/${title.replace(/\s/g, '-').toLowerCase()}`} id="link-line">
                        <div className="news-headline">
                            <h3>{title}</h3> 
                        </div>
                    </Link>
                    <div className="news-description">
                        <p>{description.slice(0, 100)+'......'}</p>
                    </div>
                    
                    <Link to={`/news/${title.replace(/\s/g, '-').toLowerCase()}`} id="link-line">
                        <div className="new-readMore">
                            <h3>Read More</h3> 
                            <div className="readMore-underline"></div> 
                        </div>
                    </Link>
                    
                </div>   
            </div>
        
        </>
    )
}
export default LatestNewsData;