import '../App.css';
import React from "react";
import CssBodyComponent from "../Components/CssBodyComponent";
import '../Styles/_media.scss';
import {useTranslation} from "react-i18next";
import MainFooter from '../Components/MainFooter';
import LatestNews from '../Pagesection/Mediapage/LatestNews';
import MediaGallery from '../Pagesection/Mediapage/MediaGallery';



function MediaPage(){
    const { t } = useTranslation();
    window.scrollTo({top: 0, behavior: 'smooth'})
    
    return(
        <>
            <CssBodyComponent />
        {/* <Header /> */}
            <div className="home-hero-img media-hero-img">
                <div className="hero-main-title-page">
                    <h2>{t("media_page_title")}</h2>
                    
                </div>

                <a href="!#" className="clicktobottom-animate">
                    <div className="indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                        {/*<span></span>*/}
                    </div>
                </a>
            </div>

            <div className="latestNewsSection">
                <LatestNews />
            </div>
            <div className="gallerySection">
                <MediaGallery />
            </div>
             

            <MainFooter />
        </>
    )
}
export default MediaPage;