import '../../app.scss';
import React, { useState } from "react";
import AllImagesOne from './ImageSection/AllImagesOne';
import AllImagesTwo from './ImageSection/AllImagesTwo';


function MediaGallery(){
    const [active, setActive] = useState("All");
    
    return(
        <>
            <div className="media-gallery">
                <h1>Gallery</h1>
                <div className="gallery-link-section">
                    <nav>
                        <button 
                        onClick={() => setActive("All")} 
                        style={ 
                            active==="All" ? 
                            {
                                "color": "#11274B",
                                "borderBottom": "2px solid #98C73D",
                            } 
                            : 
                            {
                                "color": "#8590A2",
                                "borderBottom": "none",
                            
                            }}
                        >
                            All
                        </button>
                        <button onClick={() => setActive("Most Recent")}
                        style={ 
                            active==="Most Recent" ? 
                            {
                                "color": "#11274B",
                                "borderBottom": "2px solid #98C73D",
                            } 
                            : 
                            {
                                "color": "#8590A2",
                                "borderBottom": "none",
                            
                            }}
                        >
                            Most Recent
                        </button>
                        <button onClick={() => setActive("Company News")}
                        style={ 
                            active==="Company News" 
                            ? 
                            {
                                "color": "#11274B",
                                "borderBottom": "2px solid #98C73D",
                            } 
                            : 
                            {
                                "color": "#8590A2",
                                "borderBottom": "none",
                            
                            }}
                        >
                            Company News
                        </button>
                        <button onClick={() => setActive("Events")}
                        style={ 
                            active==="Events" 
                            ? 
                            {
                                "color": "#11274B",
                                "borderBottom": "2px solid #98C73D",
                            } 
                            : 
                            {
                                "color": "#8590A2",
                                "borderBottom": "none",
                            
                            }}
                        >
                            Events
                        </button>
                        <button onClick={() => setActive("Nature")}
                        style={ 
                            active==="Nature" 
                            ? 
                            {
                                "color": "#11274B",
                                "borderBottom": "2px solid #98C73D",
                            } 
                            : 
                            {
                                "color": "#8590A2",
                                "borderBottom": "none",
                            
                            }}
                        >
                            Nature
                        </button>
                    
                    </nav>
                </div> 

                <div>
            
                    {
                        active === "All" && 
                            <div className="image-section">
                                <div className="image-row-one mt-5">
                                    <AllImagesOne/>
                                </div>
                                <div className="image-row-two mt-5">
                                    <AllImagesTwo/>
                                </div>
                                    
                            </div>
                    }
                    {
                        active === "Most Recent" && 
                            <div className="image-section">
                                <div className="image-row-one mt-5">
                                    <AllImagesTwo/>
                                </div>
                                <div className="image-row-two mt-5">
                                    <AllImagesOne/>
                                </div>
                                    
                            </div>
                    }
                    {
                        active === "Company News" && 
                            <div className="image-section">
                                <div className="image-row-one mt-5">
                                    <AllImagesOne/>
                                </div>
                                <div className="image-row-two mt-5">
                                    <AllImagesTwo/>
                                </div>
                                    
                            </div>
                    }
                    {
                        active === "Events" && 
                            <div className="image-section">
                                <div className="image-row-one mt-5">
                                    <AllImagesOne/>
                                </div>
                                <div className="image-row-two mt-5">
                                    <AllImagesTwo/>
                                </div>
                                    
                            </div>
                    }
                    {
                        active === "Nature" && 
                            <div className="image-section">
                                <div className="image-row-one mt-5">
                                    <AllImagesTwo/>
                                </div>
                                <div className="image-row-two mt-5">
                                    <AllImagesOne/>
                                </div>
                                    
                            </div>
                    }

                
                </div>  
            
            </div>
            
            
        </>
    )
}
export default MediaGallery;