import '../App.css';
import React from "react";
// import { useEffect } from 'react'

import {useTranslation} from "react-i18next";

// import translationEN from "../Assets/Language/en";
// import translationFR from "../Assets/Language/fr.json";


// import Header from '../Components/Header';
// import heroVideoHomeMain from '../Assets/Videos/centuration_coverd_vid.mp4';

import CssBodyComponent from "../Components/CssBodyComponent";
import MainHomeItemsCard from '../Pagesection/Homepage/Main_home_items_card';
import Homefranchisecenturion from '../Pagesection/Homepage/Homefranchisecenturion';
import HomepageFullVid from '../Pagesection/Homepage/HomepageFullVid';

import HomeDigitalAgency from '../Pagesection/Homepage/HomeDigitalAgency';
// import FrancisProjectCountry from '../Pagesection/FrancisProjectCountry';
import HomeCountryCards from '../Components/HomeCountryCards';
import MainFooter from '../Components/MainFooter';
// import LocatonImg1 from "../Assets/Images/Locations/Kenya-photoLocation.webp";

// import NavMenu from '../Components/NavMenu';


const buttonRoute = {
    link1_e: BusiNess => {
        return window.location.href = BusiNess;
        // console.log(r);
    },
    link2_e: conTact => {
        // console.log(y);
        return window.location.href = conTact;
    }
}

//Creating object with the variables of imported translation files

function Home(pr) {
    window.scrollTo({top: 0, behavior: 'smooth'});

    const {t} = useTranslation();

    // console.log(LangToShow[0].en_US.hero_img.title);

    return (
        <>
            <CssBodyComponent />
            {/*{heroVideoHomeMain}*/}
            <div className="main-home-hero-img homePageVideoHeroSection">
                <video src="https://src.zehadkhan.com/centuration_coverd_vid.mp4" muted autoPlay={true} loop
                       id="myVideo" className="homepage-vid-hero">
                    {/*<source src="https://res.cloudinary.com/dt55rt3o4/video/upload/v1620132670/dil-homevideo_ks92bu.mp4"*/}
                    {/*        type="video/mp4">*/}
                    {/*</source>*/}
                </video>
                <div className="homepage_hero_text_title">
                    <div className="homepage-vid-hero-inner">
                        <div className="hoome-main-hero-title">
                            <h1>
                                {t('home_title')}
                                {/*{LangToShow[0].en_US.hero_img.title}*/}
                            </h1>
                        </div>

                        <div className="home-hero-title-caption">
                            <p>{t('paragraph')}</p>
                            {/*<p>{t(LangToShow[0].en_US.hero_img.paragraph)}</p>*/}
                        </div>
                        <div className="home-hero-on-button">
                            <button className="homepage_learnmore_btn"
                                    onClick={() => buttonRoute.link1_e('/Businesses')}>{t('heroBtnLearnMore')}
                            </button>
                            <button className="homepage_contact_us_btn"
                                    onClick={() => buttonRoute.link2_e('/Contact')}>{t('heroBtnContactUs')}
                            </button>
                        </div>
                    </div>
                </div>
                <a href="#CenturationGallery" className="clicktobottom-animate">
                    <div className="indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                        {/*<span></span>*/}
                    </div>
                </a>
            </div>
            <MainHomeItemsCard/>
            <Homefranchisecenturion/>
            <HomeCountryCards/>
            <HomepageFullVid/>
            <HomeDigitalAgency/>
            <MainFooter/>
        </>

    );
}

export default Home;
