import "../App.css";
import React from "react";
import CssBodyComponent from "../Components/CssBodyComponent";
import AgrifoodsHeaderTop from '../Pagesection/Agrifood/AgrifoodsHeaderTop';
import AgrifoodPortfolio from '../Pagesection/Agrifood/AgrifoodPortfilioSection';
import Agrifoodvideosection from '../Pagesection/Agrifood/AgrifoodVideoSection';
import AgrifoodChooseUs from '../Pagesection/Agrifood/AgrifoodChooseUs';

import MainFooter from "../Components/MainFooter";


function AgrifoodPage() {
    window.scrollTo({top: 0, behavior: 'smooth'})
    return (
        <>
            <CssBodyComponent/>
            <div className="home-hero-img">
                <div className="hero-main-title-page">
                    <h2>Agrifood</h2>
                    <p><span>Our Business</span>- Agrifood</p>
                </div>
                <a href="!#" className="clicktobottom-animate">
                    <div className="indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                        {/*<span></span>*/}
                    </div>
                </a>
            </div>
            <AgrifoodsHeaderTop />
            <AgrifoodPortfolio />
            <Agrifoodvideosection />
            <AgrifoodChooseUs />

            <MainFooter />
        </>
    );
}
export default AgrifoodPage;
