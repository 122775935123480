// import logo from './logo.svg';
// import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import './app.scss';
import './App.css'

// import React, { Component } from 'react'
import React from 'react';

// import { Link } from 'react-router-dom';
// import { Switch } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes,Link } from 'react-router-dom'
// import homeimage from './Assets/Images/homepage-hero-image.jpg';
import NavMenu from './Components/NavMenu';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
// import translationFR from "./Assets/Language/fr.json";
import translationFR from "./Assets/Language/CenturionLanguage";
// import Header from './Components/Header';


const resources = {
    langEn: {
        translation: translationFR.enL.homepage,
    },
    langFr: {
        translation: translationFR.frL.homepage,
    },
};



//i18N Initialization
i18n
    .use(initReactI18next)
    .init({
        resources,
        // lng:"langEn", //default language
        lng:localStorage.getItem('langEn') || "langEn",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['querystring', 'cookie', 'path', 'navigator'],
            caches
        },
        fallbackLng: 'langEn',
        debug: true,
    });



function App() {
  return (
    <>
      <NavMenu  />
    </>
  );
}


export default App;
