import "../../app.scss";
import React from 'react';
import {useTranslation} from "react-i18next";

export default function OurbusinessHeaderTop() {
    const { t } = useTranslation();
    return(
        <>
        <div className="OurbusinessCard-header">
            <div className="OurbusinessCard-headermain">
              <h2> {t('our_business_main_title')}</h2>
            </div>
            <div className="OurbusinessCard-headertexts">
              <p>
                  {t('our_business_main_title_text')}
              </p>
            </div>
          </div>
        </>
    )
}