import "../app.scss";
import "../App.css";
import mlogo from "../Assets/Images/mlogo.png";
import downArrowIc from "../Assets/Images/donwArrowico.svg";

// import ReactDOM from 'react-dom';
import React from "react";

// import React, {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";

// import Header from './Header';
import '../CustomJs/customJs';
// import * as jQuery from 'jquery';

import Homepaages from "../pages/Home";
import AboutUspage from "../pages/About";
import AgrifoodPage from "../pages/AgrifoodPage";
import Ourbusiness from "../pages/Ourbusiness";
import SustainabilityPage from "../pages/Sustainability";
import CorporatePage from "../pages/Corporate";
import CareerPage from "../pages/CareerPage";
import MediaPage from "../pages/MediaPage";
import Location from "../pages/Locations";
// Dashboard Files
import DashboardMain from "../pages/Dashboard/Dashboard"
// Dashboard Files end
import PrivacyAndPolicy from '../pages/PrivacyPolicy'
import TermsAndConditions from '../pages/TermsAndConditions'

import PageNotFound404 from '../pages/PageNotFound404'


// import Contact from '../pages/Contact';
import {Link} from "react-router-dom";
// import { Switch } from 'react-router-dom';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
// import { useContext } from 'react';
// import homeimage from "../Assets/Images/main-common-hero-img.jpg";
import ContactPage from "../pages/ContactPage";
import NewsPageSingle from "../Pagesection/Mediapage/NewsPage/NewsPageSingle";
import i18n from "i18next";
import CenOurBusinessCardSingle from "../Pagesection/Ourbusiness/CenOurBusiness/CenOurBusinessCardSingle";
// import emailIcon from "../Assets/Images/ContactUs/email_icon.svg";
// import phoneIcon from "../Assets/Images/ContactUs/phone_icon.svg";


// eslint-disable-next-line no-undef

function nav_menu_on() {
    let xn_open = document.getElementById("nav-link-unorder_mob");
    console.log("working");
    if (xn_open.style.display === "flex") {
        xn_open.style.display = "none";
    } else {
        xn_open.style.display = "flex";
    }
}

const changeLanguageHandler = (e) => {
    const languageValue = e.target.value
    i18n.changeLanguage(languageValue);
}

function NavMenu() {
    // var [bgcolorOnScroll] = useState('#1212129c');
    return (
        <div id="main_nav_parent" className="main_nav_parent">
            <div id="nav_icon_show" className="nav-nav-unorder">

                <div className="main_nav_child">
                    <div id="nav_icon_show" className="nav-nav-unorder">
                        <Router>
                            {/*<div id="nav_site_header"  className="input nav-fixed" style={{background: `url(${homeimage})`}}>*/}

                            <div id="nav_site_header" className="input_the-nav nav-fixed"
                                // style={{background: bgcolorOnScroll}}>
                            >

                                <div className="header-nav-wrapper">
                                    <div className="site_logo_main">
                                        <div className="site_logo_image">
                                            <Link
                                                id="menu_items"
                                                className="menu_single_item"
                                                to="/Home"
                                            >
                                                <img src={mlogo} alt="Logo"/>
                                            </Link>
                                        </div>
                                        {/* <Header /> */}
                                    </div>
                                    <div
                                        onClick={nav_menu_on}
                                        id="nav-menu-icon"
                                        className="menu-icon-menu"
                                    >
                                        <MenuIcon/>
                                    </div>
                                    <div id="nav-link-unorder_mob" className="nav-link-unorder">
                                        {/*<Link*/}
                                        {/*    id="menu_items"*/}
                                        {/*    className="menu_single_item"*/}
                                        {/*    to="/"*/}
                                        {/*>*/}
                                        {/*    {" "}*/}
                                        {/*</Link>*/}

                                        <Link
                                            id="menu_items_location"
                                            className="menu_single_item"
                                            to="/About"
                                        >
                                            {" "}
                                            About Us{" "}
                                            {/*<img src={downArrowIc} alt={downArrowIc}/>*/}
                                            {/*<Link*/}
                                            {/*    id=""*/}
                                            {/*    className="menu_single_item_child_location"*/}
                                            {/*    to="/Locations"*/}
                                            {/*>*/}
                                            {/*    {" "}*/}
                                            {/*    Location{" "}*/}

                                            {/*</Link>*/}
                                        </Link>

                                        <Link style={{margin: '0 auto'}}
                                              id="menu_items_business"
                                              className="menu_single_item"
                                              to="/Businesses">
                                            {" "}
                                            Our Businesses{" "}
                                            <img src={downArrowIc} alt={downArrowIc}/>
                                            <div className="drop-down-parent">
                                                <div className="dropdown-items">
                                                    <Link
                                                        id=""
                                                        className="menu_single_item_child"
                                                        to="/Agrifood"
                                                    >
                                                        {" "}
                                                        Agrifood{" "}

                                                    </Link>
                                                    <Link
                                                        id=""
                                                        className="menu_single_item_child"
                                                        to="/Agrifood"
                                                    >
                                                        {" "}
                                                        Laundromates{" "}

                                                    </Link>
                                                    <Link
                                                        id=""
                                                        className="menu_single_item_child"
                                                        to="/Agrifood"
                                                    >
                                                        {" "}
                                                        Consumable{" "}

                                                    </Link>
                                                    <Link
                                                        id=""
                                                        className="menu_single_item_child"
                                                        to="/Agrifood"
                                                    >
                                                        {" "}
                                                        Photo & Impression{" "}

                                                    </Link>
                                                    <Link
                                                        id=""
                                                        className="menu_single_item_child"
                                                        to="/Agrifood"
                                                    >
                                                        {" "}
                                                        Pizza & Choco{" "}

                                                    </Link>
                                                    <Link
                                                        id=""
                                                        className="menu_single_item_child"
                                                        to="/Agrifood"
                                                    >
                                                        {" "}
                                                        Draft Beer{" "}

                                                    </Link>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link
                                            id="menu_items"
                                            className="menu_single_item"
                                            to="/Sustainability"
                                        >
                                            {" "}
                                            Sustainability{" "}
                                        </Link>
                                        {/*<Link*/}
                                        {/*    id="menu_items"*/}
                                        {/*    className="menu_single_item"*/}
                                        {/*    to="/Corporate"*/}
                                        {/*>*/}
                                        {/*    {" "}*/}
                                        {/*    Corporate{" "}*/}
                                        {/*    <img src={downArrowIc} alt={downArrowIc}/>*/}
                                        {/*</Link>*/}
                                        <Link
                                            id="menu_items_location"
                                            className="menu_single_item"
                                            to="/Locations"
                                        >
                                            {" "}
                                            Location{" "}
                                            {/*<img src={downArrowIc} alt={downArrowIc}/>*/}
                                        </Link>

                                        <Link
                                            id="menu_items"
                                            className="menu_single_item"
                                            to="/Media"
                                        >
                                            {" "}
                                            Media{" "}
                                        </Link>
                                        <Link
                                            id="menu_items"
                                            className="menu_single_item"
                                            to="/Career"
                                        >
                                            {" "}
                                            Career{" "}
                                        </Link>
                                        <Link
                                            id="menu_items"
                                            className="menu_single_item menu_single_contact"
                                            to="/Contact"
                                        >
                                            {" "}
                                            Contact{" "}
                                        </Link>

                                        <select className="custom-select-lang" style={{width: 200}}
                                                onChange={changeLanguageHandler}>
                                            <option value="langEn">
                                                🇺🇸

                                            </option>
                                            <option className="languageOptionFr" value="langFr">
                                                🇫🇷
                                            </option>
                                        </select>

                                    </div>

                                    {/* call name of the variable from  the translation.json file to t() method */}
                                </div>
                            </div>

                            <div className="CenturionMainData">
                                <Routes>
                                    <Route path="/" element={<Homepaage/>}/>
                                    <Route path="/Home" element={<Homepaage/>}/>
                                    <Route path="/About" element={<About/>}/>
                                    <Route path="/Locations" element={<Locations/>}/>


                                    <Route path="/Businesses" element={<Businesses/>}/>
                                    <Route path="/Agrifood" element={<Agrifood/>}/>
                                    <Route path="/Businesses/:title" element={<BusinessCardInfo/>}/>


                                    <Route path="/Sustainability" element={<Sustainability/>}/>

                                    <Route path="/Corporate" element={<Corporate/>}/>
                                    <Route path="/Media" element={<Media/>}/>

                                    <Route path="/news/:title" element={<News/>}/>

                                    <Route path="/Career" element={<Career/>}/>
                                    <Route path="/Contact" element={<Contact/>}/>
                                    <Route path="/Dashboard" element={<Dashboard/>}/>
                                    <Route path="/privacy-policy" element={<PrivacyAndPolicy/>}/>
                                    <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                                    <Route path="*" element={<PageNotFound404/>}/>
                                </Routes>
                            </div>
                        </Router>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Homepaage = () => {
    return (
        <>
            <Homepaages/>
        </>
    );
}

const About = () => {
    return (
        <>
            <AboutUspage/>
        </>
    );
}

const Locations = () => {
    return (
        <>
            <Location/>
        </>
    );
}

const Agrifood = () => {
    return (
        <>
            <AgrifoodPage/>
        </>
    );
}

const Businesses = () => {
    return (
        <>
            <Ourbusiness/>
        </>
    );

}

const BusinessCardInfo = () => {
    return (
        <>
            <CenOurBusinessCardSingle/>
        </>
    )
}

const Sustainability = () => {
    return (
        <>
            <SustainabilityPage/>
        </>
    );
}

function Corporate() {
    return (
        <>
            <CorporatePage/>
        </>
    );
}

const Media = () => {
    return (
        <>
            <MediaPage/>
        </>
    );
}

const News = () => {
    return (
        <>
            <NewsPageSingle/>
        </>
    )
}

const Career = () => {
    return (
        <>
            <CareerPage/>
        </>
    );
}

const Contact = () => {
    return (
        <>
            <ContactPage/>
        </>

    );


}
const Dashboard = () => {
    return (
        <>
            <DashboardMain/>
        </>
    )
}


export default NavMenu;
