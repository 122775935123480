import '../../../app.scss';
import React from "react";
import galleryPic3 from "../../../Assets/Images/Media/gallery/galleryPic3.webp";
import galleryPic4 from "../../../Assets/Images/Media/gallery/galleryPic4.webp";
import galleryPic5 from "../../../Assets/Images/Media/gallery/galleryPic5.webp";

function AllImagesTwo(){
    return(
        <>
            <div className="All-ImageSection container container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <img className="img-fluid p-3" src={galleryPic3} alt={galleryPic3} />
                    </div>
                    <div className="col-md-4">
                        <img className="img-fluid p-3" src={galleryPic4} alt={galleryPic4} />
                    </div>
                    <div className="col-md-4">
                        <img className="img-fluid p-3" src={galleryPic5} alt={galleryPic5} />
                    </div>
                </div>
            
            </div> 
            
        
        </>
    )
}
export default AllImagesTwo;