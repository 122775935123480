import '../App.css';
import React from "react";
import {useTranslation} from "react-i18next";
import CssBodyComponent from "../Components/CssBodyComponent";
import careerPhoto_one from '../Assets/Images/Career/african-businesswoman-smiling.png';
import careerPhoto_Two from '../Assets/Images/Career/career-sec-2.webp';
import careerIcon_one from '../Assets/Images/careerIcon_one.jpg';
import careerIcon_two from "../Assets/Images/careerIcon_two.jpg";
import careerIcon_three from "../Assets/Images/careerIcon_three.jpg";
// import careerIcon_four from "../Assets/Images/";

import MainFooter from '../Components/MainFooter';
import {Button, Accordion} from 'react-bootstrap';

function CareerPage() {
    const { t } = useTranslation();
    window.scrollTo({top: 0, behavior: 'smooth'})
    return (
        <>
            <CssBodyComponent />
        {/* <Header /> */}
        <div className="home-hero-img career-hero-img">
            <div className="hero-main-title-page">
                <h2>{t('career_page_title')}</h2>
                <h3>{t('career_page_tag_line')}</h3>
            </div>
            <a href="!#" className="clicktobottom-animate">
                <div className="indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                    {/*<span></span>*/}
                </div>
            </a>
        </div>
        <div className="career-person-work">
            <div className="career-person-work-inner">
                <div className="career-person-left-txt">
                <p style={{'font-size': '30px'}}>{t('career_page_tag_line_impacting')}</p>
                    
                    <h3> {t('career_page_why_work_title')}</h3>
                    <p >
                        {t('career_page_why_work_title_txt_1')}
                        <br/>
                        <br/>
                        {/* {t('career_page_why_work_title_txt_2')} */}
                         </p>
                    <Button variant="light" className="info-button">{t('career_page_why_work_abt_more_btn')}</Button>
                </div>
                <div className="careerPhoto-men">
                    <img src={careerPhoto_one} alt="careerPhoto_one"/>
                </div>
            </div>
        </div>

        {/*Experience*/}
        <div className="careerExp-main">
            <div className="career-setion-Exp">

                <div className="careerPhoto">
                    <img src={careerPhoto_Two} alt="careerPhoto_Two"/>
                </div>

                <div className="secTwo-info">
                    <h3>{t('career_page_join_us_xp_title')}</h3>
                    <p>
                        {t('career_page_join_us_xp_text')}
                    </p>

                    <div className="career-icon-main">
                        <div className="career-exp-small-ico">
                            <img src={careerIcon_one} alt={careerIcon_one}/>
                            <h5> {t('career_page_join_flexible_work')}</h5>
                        </div>
                        <div className="career-exp-small-ico">
                            <img src={careerIcon_two} alt={careerIcon_two}/>
                            <h5> {t('career_page_join_yearly_tour')}</h5>
                        </div>
                        <div className="career-exp-small-ico">
                            <img src={careerIcon_three} alt={careerIcon_three}/>
                            <h5> {t('career_page_join_free_holiday')}</h5>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        {/*Our values*/}
        <div className="careerValues-main">
            <div className="our-values-accordion-left">
                <h3>{t('career_page_our_value_title_faqs')}</h3>
            </div>
            <div className="our-values-accordion">

                <div className="collapse-area our-values-accordion-right">
                    <Accordion>
                        <Accordion.Item eventKey="0" className="collapse-optn">
                            <Accordion.Header>
                                {/* <span className="collapse-num">01</span>  */}
                                {t('career_page_our_value_title_faq_Ques_1')}
                            </Accordion.Header>
                            <Accordion.Body>
                                {t('career_page_our_value_title_faq_Ans_1')}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="collapse-optn">
                            <Accordion.Header>
                                {/* <span className="collapse-num">02</span> */}
                                {t('career_page_our_value_title_faq_Ques_2')}
                            </Accordion.Header>
                            <Accordion.Body>
                            {t('career_page_our_value_title_faq_Ans_2')}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="collapse-optn">
                            <Accordion.Header>
                                {/* <span className="collapse-num">03</span> */}
                                {t('career_page_our_value_title_faq_Ques_3')}
                            </Accordion.Header>
                            <Accordion.Body>
                                {t('career_page_our_value_title_faq_Ans_3')}
                            </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="3" className="collapse-optn">
                            <Accordion.Header><span className="collapse-num">04</span>We celebrate expressing ideas,
                                whereve they come from.</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4" className="collapse-optn">
                            <Accordion.Header><span className="collapse-num">05</span>We evolve, not
                                revolve.</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5" className="collapse-optn">
                            <Accordion.Header><span className="collapse-num">06</span>We don’t let hierarchy hinder
                                innovation.</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                est laborum.
                            </Accordion.Body>
                        </Accordion.Item> */}


                    </Accordion>
                </div>
            </div>
        </div>


        <MainFooter/>
    </>)
}

export default CareerPage;