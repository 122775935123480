import React from 'react';
// import CssBodyComponent from "../Components/CssBodyComponent";
const PageNotFound404= () =>{
    return(
        <div className="error-not-foundpage"
             style={{textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}
        >
            <div>
                <h2 style={{fontSize:'65px', fontWeight:'700'}}>404 Error</h2>
                <h3>Page Not Found</h3>
                <div className="backtohomepage"><a href={document.location.origin}> <span>&#8592;</span> Back to home</a></div>
            </div>
        </div>
    )
}

export default PageNotFound404;