import "../../app.scss";
import agriworkers from "../../Assets/Images/Agrifood/agrifood-worker-ico.svg";
import agriexpertise from "../../Assets/Images/Agrifood/agrifood-expertise-icon.svg";
import agriiprotection from "../../Assets/Images/Agrifood/agrifood-protection-ico.svg";
import agriifoodhelpline from "../../Assets/Images/Agrifood/agrifood-helpline-ico.svg";

export default function AgrifoodChooseUs() {
    return (
        <>
            <div className="why-chooseus-main">
                <div className="why-choose-us-left-title">
                    <h2>Why Choose Us</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy.</p>
                </div>
                <div className="why-choose-us-right-client-portal">
                    <div className="why-choose-us-card">
                        <img src={agriworkers} alt=""/>
                        <div className="why-choose-us-card-title">
                            <h4>Certified Workers</h4>
                        </div>
                    </div>

                    <div className="why-choose-us-card">
                        <img src={agriiprotection} alt=""/>
                        <div className="why-choose-us-card-title">
                            <h4>Certified Workers</h4>
                        </div>
                    </div>


                    <div className="why-choose-us-card">
                        <img src={agriexpertise} alt=""/>
                        <div className="why-choose-us-card-title">
                            <h4>Certified Workers</h4>
                        </div>
                    </div>


                    <div className="why-choose-us-card">
                        <img src={agriifoodhelpline} alt=""/>
                        <div className="why-choose-us-card-title">
                            <h4>Certified Workers</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}