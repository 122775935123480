import "../../app.scss";
import agrifoodvegs from "../../Assets/Images/Agrifood/agrifood-vegs-img.webp";
export default  function AgrifoodVideoSection (){
    return(
        <>
            <div className="agrifood-video-section-main">
                <img src={agrifoodvegs} alt=""/>
            </div>
        </>
    )
}